import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { page } from 'ui/app/_content/page-about-us';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {

  page = page;

  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(page.meta.title)
  }

}
