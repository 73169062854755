import { Component, OnInit } from '@angular/core'
import { AnimationOptions } from 'ngx-lottie'
import { AnimationItem } from 'ngx-lottie/src/symbols'

@Component({
  selector: 'home-how',
  templateUrl: './how.component.html',
  styleUrls: ['./how.component.scss']
})
export class HowComponent implements OnInit {

  showTitle = false
  private anim?: AnimationItem
  lineOptions: AnimationOptions = {
    path: '/assets/why-line.json',
    autoplay: false,
    loop: 0
  }

  lineStyles: Partial<CSSStyleDeclaration> = {
    position: 'absolute',
    left: '0',
    right: '0',
  }

  iconStyle = {
    width: '4em',
    heigh: '4em'
  }

  constructor() { }

  ngOnInit(): void {
  }

  lineCreated(anim: AnimationItem): void {
    this.anim = anim
  }

  animateLine(event: number): void {
    if (event >= 0.5) {
      this.anim?.play()
      this.showTitle = true
    }

  }

  offscreen(): void {
    this.showTitle = false
    this.anim?.goToAndStop(0)
  }
}
