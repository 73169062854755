export const page = {
    head: {
        icon: '/assets/logo.svg',
        title: "IT Consultancy",
        p: [
            `KaapiSoft offers consultancy services to 
             clients who need to ramp up the specs of 
             their internal IT processes and align with 
             the best industry practices and standards.`
        ]
    },
    sectionA: {
        title: `Here are some of our consultation target 
                points you will benefit from`,
        items: [
            { 
                icon: '/assets/pages/it-consultancy-icon01.svg',
                title: `RESOURCE MANAGEMENT`,
                text: `Reports and recommendations on how to manage internal resources more efficiently for better productivity and overcome bottlenecks within and across key business units. Advise on how to utilize internal assets and IT infrastructure for optimal performance.`
            },
            { 
                icon: '/assets/pages/it-consultancy-icon02.svg',
                title: `BEST PRACTICES IN PRODUCT DEVELOPMENT`,
                text: `How to follow and use the best practices needed in developing products such as applications, processes and client facing services.`
            },
            { 
                icon: '/assets/pages/it-consultancy-icon03.svg',
                title: `SPECS FOR IT PRODUCTS`,
                text: `Define specs and create documentation on your IT products used by your teams. Audit internal IT systems and products for maximum compliance to international standards and operational efficiency.`
            },
            { 
                icon: '/assets/pages/it-consultancy-icon04.svg',
                title: `SETTING & MAINTENANCE OF SOFTWARE METHODOLOGY`,
                text: `Using methodologies such as Agile development methodology and DevOps deployment methodology to plan and structure the product development cycle in phases thus maximizing productivity and minimizing resource inefficiencies.`
            },
            { 
                icon: '/assets/pages/it-consultancy-icon05.svg',
                title: `MENTORING OF IT TEAM & DEVELOPERS`,
                text: `Lending our strong experience in software development and systems design to train and mentor your team of developers and staff members in your IT department.`
            },
            { 
                icon: '/assets/pages/it-consultancy-icon06.svg',
                title: `SOFTWARE DESIGN OPTIMIZATION & BEST PRACTICES`,
                text: `Train and guide your team of developers on how to use the best practices in coding, collaborating and documenting their work to optimize both their workflow and assure final product is of optimum quality.`
            },
            { 
                icon: '/assets/pages/it-consultancy-icon07.svg',
                title: `TECHNICAL ARCHITECTURE`,
                text: `Audit your internal systems and processes and design the best architecture to run a <a href="/smarter-applications-and-workflow-automation/">seamless workflow</a> within your cross-functional teams.`
            }
        ]
    },
    sectionB: {
        button: 'GET IN TOUCH'
    },
    meta: {
        title: `IT Consultancy Malta`,
        description: `IT and ICT Consultancy in Malta - KaapiSoft offers consultancy services to clients who need to ramp up the specs of their internal IT processes and align with the best industry practices and standards. Here are some of our consultation target points you will benefit from Resource Management Reports and recommendations on how to manage internal resources more efficiently for better productivity`,
        keywords: `
            it consultancy,
            it consulting services,
            it consulting firms,
            it consulting companies,
            it advisory,
            it business consultant,
            it strategy consulting,
            it infrastructure consulting,
            it management consulting,
            it consultancy near me,
            best it consulting firms,
            it solutions consulting,
            top it consulting companies,
            it consulting companies near me,
            it consulting and services,
            freelance it consultant`
    }

}
