import { Injectable } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'

import { QuestionBase } from './question-base'

@Injectable()
export class QuestionControlService {
  constructor(
    private formBuilder: FormBuilder
  ) { }

  toFormGroup(questions: QuestionBase<string>[]): FormGroup {
    const group: any = {}


    questions.forEach(question => {

      // const userForm = this.formBuilder.group({
      //   [question.key]: [null, Validators.required],

      // });

      const value = question.value || null
      group[question.key] = question.required
        ? new FormControl(value, [Validators.required])
        : new FormControl(value)
    })
    return new FormGroup(group)
  }
}
