import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChildren,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription, timer } from 'rxjs';
import { skip } from 'rxjs/operators';
import { inOutAnimation } from './animations';
import { clientStories } from './stories';
import * as Hammer from 'hammerjs';

@Component({
  selector: 'home-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
  animations: [inOutAnimation],
})
export class SuccessComponent implements OnInit, AfterViewInit, OnDestroy {
  currentStory = 0;
  stories: any = clientStories;

  @ViewChildren('storiesContainer') storiesContainer!: QueryList<ElementRef>;
  @ViewChildren('storiesButton') storiesButton!: QueryList<ElementRef>;
  @ViewChild('storyContainer', { static: true }) storyContainer!: ElementRef;

  constructor(
    private _renderer: Renderer2,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef
  ) {}

  timerSubscription?: Subscription;
  storiesContainerSubscription?: Subscription;
  slideTimer = timer(0, 10800);

  get story() {
    return this.stories[this.currentStory];
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.showStory(0, this.storiesContainer, this.storiesButton);
    this.addSwipeListener();
  }

  ngOnDestroy(): void {
    this.stopTimer();
    this.storiesContainerSubscription?.unsubscribe();
  }

  startTimer() {
    this.timerSubscription?.unsubscribe();
    this.timerSubscription = this.slideTimer
      .pipe(skip(1))
      .subscribe((x) => this.next());

    this.storiesContainerSubscription?.unsubscribe();
    this.storiesContainerSubscription = this.storiesContainer.changes.subscribe(
      (y) => this.showStory(0, y, this.storiesButton)
    );
  }

  stopTimer() {
    this.timerSubscription?.unsubscribe();
  }

  next(): void {
    this.currentStory += 1;
    if (this.currentStory + 1 > this.stories.length) {
      this.currentStory = 0;
    }
    this.showStory(
      this.currentStory,
      this.storiesContainer,
      this.storiesButton
    );
  }

  showSlide(slideIndex: number) {
    this.stopTimer();
    this.currentStory = slideIndex;
    this.showStory(slideIndex, this.storiesContainer, this.storiesButton);
  }

  openCaseStudy(storyIdx: number, btnIdx: number): void {
    window.open(
      this.stories[this.currentStory].stories[storyIdx].caseStudies[btnIdx]
        .link,
      '_blank'
    );
  }

  showStory(idx: number, container: any, button?: any): void {
    if (container !== null) {
      container.map((item: ElementRef, index: number) => {
        this._renderer.addClass(item.nativeElement, 'hidden');
        this._renderer.removeClass(item.nativeElement, 'slide-left');
        this._renderer.removeStyle(item.nativeElement, 'padding-top');

        if (index === idx) {
          this._renderer.removeClass(item.nativeElement, 'hidden');
          this._renderer.addClass(item.nativeElement, 'slide-left');

          // Check if the screen width is less than or equal to 768px (mobile view)
          if (window.innerWidth <= 768) {
            this._renderer.setStyle(item.nativeElement, 'padding-top', '75px');
          }

          button = !(button instanceof QueryList) ? this.storiesButton : button;

          button.forEach((button: ElementRef, btnIdx: number) => {
            this._renderer.removeClass(button.nativeElement, 'active');
            if (btnIdx === idx) {
              this._renderer.addClass(button.nativeElement, 'active');
            }
          });

          // To center the story when shown
          this.cdr.detectChanges();
          this.centerStory(idx);
        }

        return item;
      });
    }
  }

  centerStory(idx: number): void {
    const storyElement = this.elRef.nativeElement.querySelector(
      `#story-${idx}`
    );
    if (storyElement) {
      if (window.innerWidth > 768) {
        storyElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      } else {
        storyElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }

  previousSlide(): void {
    this.currentStory -= 1;
    if (this.currentStory < 0) {
      this.currentStory = this.stories.length - 1;
    }
    this.showStory(
      this.currentStory,
      this.storiesContainer,
      this.storiesButton
    );
  }

  nextSlide(): void {
    this.currentStory += 1;
    if (this.currentStory >= this.stories.length) {
      this.currentStory = 0;
    }
    this.showStory(
      this.currentStory,
      this.storiesContainer,
      this.storiesButton
    );
  }

  addSwipeListener(): void {
    const isMobileOrTablet =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobileOrTablet) {
      const hammer = new Hammer(this.storyContainer.nativeElement);

      hammer.on('swipeleft', () => {
        this.nextSlide();
      });
      hammer.on('swiperight', () => {
        this.previousSlide();
      });
    }
  }
}
