import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { FormControl } from '@angular/forms'
import { take } from 'rxjs/operators'

import { HubspotForm } from 'ui/app/core/interfaces/hubspot-form'
import { HubspotStore } from 'ui/app/core/services/hubspot/hubspot.store'

@Component({
  selector: 'home-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {
  form: HubspotForm | undefined

  portalId = 8498700
  hubspotFormId = 'ca8fd09e-8926-47ef-bd6a-e30e0535b1a3'

  constructor(
    private hubspotStore: HubspotStore
  ) {
    // this.hubspotStore.getForm(this.hubspotFormId)
    //   .pipe(
    //     take(1)
    //   )
    //   .subscribe((form) => {
    //     this.form = form
    //   })
  }

  newTab(link:string) {
    window.open(link, '_blank')
  }

  emailFormControl?: FormControl

  ngOnInit(): void {}

  submitForm(formData: any): void {
    console.log("submitting form")
    this.hubspotStore.postForm(
      {
        formId: this.hubspotFormId,
        portalId: this.portalId,
        data: formData
      }
    ).subscribe((res) => {

      console.log({ res })
    })
  }

}
