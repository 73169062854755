import { trigger, transition, style, animate, keyframes, query, stagger } from '@angular/animations'

export const inout =
  trigger('inout',
    [
      transition(
        ':enter',
        [
          style({ transform: 'translateY(200%)' }),
          animate(
            '200ms cubic-bezier(.11,.61,.57,.99)',
            style({ transform: 'translateY(0)' })
          )
        ]
      ),
      transition(
        ':leave',
        [
          style({ transform: 'translateY(0)' }),
          animate(
            '200ms ease-in',
            style({ transform: 'translateY(200%)' })
          )
        ]
      )
    ]
  )