<div id="top-menu">
    <div class="hamburger"
        (click)="toggle()"
        [ngClass]="{ 'visible' : show }">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</div>
<div [@inOutAnimation]
    *ngIf="show"
    class="full-container">
    <div class="full b3"></div>
    <div class="full b2"></div>
    <div class="full b1"></div>
    <div class="full menu">
        <div class="left">
            <div class="items">
                <div (click)="openHomeFragment('')" inlineSVG="/assets/kaapisoft-logo.svg"></div>
                <h2 (click)="nav('/about-us')">Who We Are</h2>
                <h2 (click)="openHomeFragment('how')">How We Can Help</h2>
                <h2 (click)="openHomeFragment('why')">Why KaapiSoft?</h2>
                <h2 (click)="openHomeFragment('success')">Our Success Stories</h2>
                <h2 (click)="openBlog()">Blog</h2>
                <h2 (click)="openHomeFragment('contact')">Contact Us</h2>
            </div>
        </div>
        <div class="right">
            <div class="items">
                <div *ngFor="let m of right" 
                    class="row"
                    (click)="nav(m.link)">
                    <div class="icon" 
                        [inlineSVG]="m.icon"
                        [setSVGAttributes]="{ width: '100%', height: '100%' }"> </div>
                    <p>{{ m.title }}</p>
                </div>
            </div>
        </div>
        <div class="overlay"></div>
    </div>
</div>
