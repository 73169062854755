import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { page } from '../../../_content/page-systems-integration';

@Component({
  selector: 'app-systems-integration',
  templateUrl: './systems-integration.component.html',
  styleUrls: ['./systems-integration.component.scss']
})
export class SystemsIntegrationComponent implements OnInit {

  page = page

  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(this.page.meta.title)
    this.metaService.updateTag(
      { name: 'keywords', content: this.page.meta.keywords })
    this.metaService.updateTag(
      { name: 'description', content: this.page.meta.description })
    this.metaService.updateTag(
      { name: 'robots', content: 'index, follow' })
  }

}
