export const clientStories = [
  {
    stories: [
      {
        content: [
          "KaapiSoft helped us create a Power App to assess our clients' IT infrastructure security. With just a few clicks, we are able to conduct comprehensive security assessments and identify vulnerabilities. The real-time reporting and analytics generated from the app empower us to make a thorough evaluation of our client's security setup and procedures and enhance our overall position as Technology security experts in the market.",
          "We wholeheartedly recommend KaapiSoft to any organisation seeking a reliable and efficient solution to safeguard their digital assets"
        ],
        person: {
          name: 'Ivan Galea - Product Manager',
          company: 'bmit',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_BMIT2.pdf',
            button: 'BMIT CASE STUDY'
          }
        ]
      },
      {
        content: [
          'As a leading data centre serving a number of high profile industries, we wanted to provide our customers with a highly functional and secure portal that integrates with our systems to provide a holistic view of their services hosted with us.',
          'It was clear from the start that KaapiSoft understood our requirements and worked hard to deliver these in a timely fashion. The attention to detail was fantastic and ongoing support provided is great.',
        ],
        person: {
          company: 'bmit',
          name: 'Sean Cohen, Head of Technical Operations'
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_BMIT.pdf',
            button: 'BMIT CASE STUDY'
          }
        ]
      }
    ],
    logo: '/assets/home/logos/bmit_logo.svg',
    width: 104
  },
  {
    stories: [
      {
        content: [
          'KaapiSoft quickly understood the nature of the problem we were facing in our day to day operations. Once we were clear on the goals, we adopted a weekly, open communication project setup, taking an incremental development approach that ensured KaapiSoft quickly delivered what we needed, reducing definition time and a quick product delivery.',
          'The solution delivered is user friendly, was easy to introduce into existing processes and is set to improve our efficiency.'
        ],
        person: {
          name: 'Steven Sargent',
          position: 'Senior Manager',
          company: 'GO plc',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_GoPlc.pdf',
            button: 'OPEN CASE STUDY'
          }
        ]
      }
    ],
    logo: '/assets/home/logos/go-plc.svg',
    width: 77
  },
  {
    stories: [
      {
        content: [
          'We have worked with KaapiSoft on a number of projects related to our core systems due to our expanding business and need for professional outsourcing partners. It was important for us to find a company we could trust and people who are able to easily understand requirements and maintain a constant line of communication which is vital in any software development project. The detailed planning, timely delivery and vast technical knowledge of KaapiSoft meant that we never had to look elsewhere.',
        ],
        person: {
          name: 'Andre Genovese, CEO',
          company: 'betstudios',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_BetStudios.pdf',
            button: 'OPEN CASE STUDY'
          }
        ]
      }
    ],
    logo: '/assets/home/logos/betstudios.svg',
    width: 175
  },
  {
    stories: [
      {
        content: [
          'We have involved KaapiSoft in several development projects over the years and they have consistently delivered with an unmatched level of professionalism every single time. Working with KaapiSoft, and making use of their expertise, has added great value to our business',
        ],
        person: {
          name: 'Matt Rizzo, CEO',
          company: 'Cleverbit',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_Cleverbit.pdf',
            button: 'Cleverbit CASE STUDY'
          }
        ]
      }
    ],
    logo: '/assets/home/logos/cleverbit.svg',
    width: 184
  },
  {
    stories: [
      {
        content: [
          "We have previously used KaapiSoft's services, and the success achieved with the previous assistance is what pushed us to get in touch with them again. This time around, KaapiSoft helped us automate our accounting systems, leading to significant cost savings and reduction in administrative duties. The streamlined process they have integrated has allowed us to allocate resources more efficiently. This has led to us focusing on other important areas of our business.",
          "It's been a game-changer for us! We highly recommend KaapiSoft's solutions to any business looking to increase efficiency and maximise resources."
        ],
        person: {
          name: 'Peter Wuyts, CEO',
          company: 'See3D',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_See3D1.pdf',
            button: 'See3D CASE STUDY 1'
          },
          {
            link: '/assets/case-study/CaseStudy_See3D2.pdf',
            button: 'See3D CASE STUDY 2'
          }
        ]
      },
      {
        content: [
          'We had a very particular project idea in mind that was unique and demanded a certain level of detail and complexity. KaapiSoft came in as the perfect outsourcing solution. They handled the project superbly starting from product planning, prototyping, research and development up to the final product. Highly recommended for anyone looking for a custom product development done by people who really know their stuff',
        ],
        person: {
          name: 'Peter Wuyts, CEO',
          company: 'See3D',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_See3D.pdf',
            button: 'See3D CASE STUDY'
          }
        ]
      }
    ],
    logo: '/assets/home/logos/see3d.svg',
    width: 114
  },
  {
    stories: [
      {
        content: [
          'KaapiSoft was an essential element in the Taste Please project. They provided the necessary expertise and skills to plan and execute the development of the platform with formidable and impeccable efficacy.  They ran a smooth workflow, keeping close proximity to the rest of the team and delivering the product right on cue.  Five stars and highly recommended!',
        ],
        person: {
          name: 'Frank Lantz, CEO',
          company: 'TastePlease',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_TastePlease.pdf',
            button: 'OPEN CASE STUDY'
          }
        ]
      }
    ],
    logo: '/assets/home/logos/tasteplease.svg',
    width: 175
  },
  {
    stories: [
      {
        content: [
          'The Leave App developed by KaapiSoft through Microsoft’s Power Platform has been a useful resource in managing our workforce. It has streamlined the employee leave process, making it more efficient and accessible. Our HR team has saved time thanks to this app, and employees have responded positively to it. We would highly recommend KaapiSoft to anyone looking for a reliable and talented software development partner.',
        ],
        person: {
          name: 'John Ellul & Clint Deguara (Co-founders, Veracloud)',
          company: 'VeraCloud',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_VeraCloud.pdf',
            button: 'OPEN CASE STUDY'
          }
        ]
      }
    ],
    logo: '/assets/home/logos/veracloud.svg',
    width: 175
  },
  {
    stories: [
      {
        content: [
          "We recently commissioned KaapiSoft to create a custom online database to facilitate the management of our open jobs. After designing and discussing our needs with Mr William Bellia, who was extremely helpful and understanding of what we wanted, KaapiSoft worked diligently to create a user-friendly and efficient digital system that streamlined our processes and centralised our data. Thanks to their efforts, we were able to improve our data management, have full visibility of work carried out and produce reports on the fly, as well as save valuable time. Throughout the entire process, I was in regular contact with William at KaapiSoft to fine-tune the software to meet our expectations. Anyone I spoke to at KaapiSoft was always professional, communicative, and responsive to our feedback. They always kept our objective in mind.",
          "Our office would highly recommend them to anyone who needs a custom system designed as we couldn't be happier with the final product."
        ],
        person: {
          name: 'Anthony Mizzi (Office Manager)',
          company: 'Gozo Notary',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_GozoNotary.pdf',
            button: 'OPEN CASE STUDY'
          }
        ]
      },
    ],
    logo: '/assets/home/logos/gozonotary.svg',
    width: 175
  },
  {
    stories: [
      {
        content: [
          "It has been a pleasure working with William and the team at Kaapisoft. Using their vast experience, we have been able to put in place new ways of working that have benefited our entire team and our clients. Anyone given the chance to work with KaapiSoft should jump at the chance",
        ],
        person: {
          name: 'Calvin Briffa (Managing Director)',
          company: 'Born',
        },
        caseStudies: [
          {
            link: '/assets/case-study/CaseStudy_Born.pdf',
            button: 'OPEN CASE STUDY'
          }
        ]
      }
    ],
    logo: '/assets/home/logos/borndigital.svg',
    width: 175
  }
];