<div id="success">
    <div class="bleft">
        <ng-lottie-autoplay src="/assets/success-squares.json" class="squares"></ng-lottie-autoplay>
    </div>
    <section class="content">
        <div class="pad">
            <h1>Our Success Stories</h1>
            <p class="head">We have a portfolio of success stories under our belt that cover a wide area of business and
                technical projects.</p>
            <smooth-height [trigger]="currentStory" observeIntersection [intersectionSteps]='2'
                (onAppearing)="startTimer()" (didDisappear)="stopTimer()">
                <div class="story" #storyContainer>
                    <!-- -----Next and Prev Buttons---- -->
                    <div class="slide-buttons">
                        <button class="slide-button left" (click)="previousSlide()">
                            ←
                        </button>
                        <button class="slide-button right" (click)="nextSlide()">→</button>
                    </div>
                    <!--  -->
                    <ng-container *ngFor="let clientStory of story.stories; let i = index">
                        <div class="text hidden" id="story-{{i}}" #storiesContainer>
                            <div class="quote shadow">
                                <div class="opening"></div>
                                <img class="slogo" src="{{ story.logo }}" width="{{ story.width }}">
                                <p *ngFor="let paragraph of clientStory.content">{{ paragraph }}</p>
                                <p *ngIf="clientStory.person.name || clientStory.person.position" class="person">
                                    <span *ngIf="clientStory.person.name" class="name">{{ clientStory.person.name
                                        }}</span><br>
                                    <span *ngIf="clientStory.person.position" class="position">{{
                                        clientStory.person.position }}</span><br>
                                </p>
                                <div class="kbutton-container">
                                    <ng-container *ngFor="let kb of clientStory.caseStudies; let btnIdx = index">
                                        <div class="kbutton" (click)="openCaseStudy(i, btnIdx)">{{ kb.button }}</div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="story.stories.length > 1">
                        <div id="sbutton-container">
                            <div *ngFor="let b of story.stories; let i = index" class="sbutton" #storiesButton
                                observeIntersection (onAppearing)="showStory(0, storiesContainer, storiesButton)"
                                (click)="showStory(i, storiesContainer, storiesButton)">
                            </div>
                        </div>
                    </ng-container>
                </div>
            </smooth-height>
        </div>
        <div id="logos">
            <div class="slogo" *ngFor="let s of stories; let i = index" (click)="showSlide(i)"
                [ngClass]="{ 'selected': i == currentStory } ">
                <div [inlineSVG]="s.logo" [setSVGAttributes]="{  }"></div>
            </div>
        </div>
    </section>
</div>