import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { Title, Meta } from '@angular/platform-browser'
import { KaapisoftCMSStore } from 'ui/app/core/services/kaapisoft-cms/kaapisoft-cms.store'

import { WPPageData } from 'ui/app/core/interfaces/wp-page-data'

@Component({
  templateUrl: './dynamic-pages.component.html',
  styleUrls: ['./dynamic-pages.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DynamicPagesComponent implements OnInit {

  pages?: WPPageData[]
  page: WPPageData | undefined

  loading = true
  notFound = false

  constructor(
    private cmsStore: KaapisoftCMSStore,
    private titleService: Title,
    private metaService: Meta,
    public router: Router)
  {

    console.log('GETTING PAGES')

    cmsStore.getPages()
      .subscribe(
        (pages) => {
          this.pages = pages

          const slug = this.router.url.replace(/\//g, '')
          this.page = this.pages?.find((p) => p.slug === slug)
          this.notFound = !this.page
        }
      )
  }

  ngOnInit(): void {
    this.loading = true

    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd && this.pages) {

        const slug = event.urlAfterRedirects.replace(/\//g, '')
        this.page = this.pages?.find((p) => p.slug === slug)
        this.pageLoaded()
      }
    })
  }

  pageLoaded() {
    console.log('wp page loaded')
    window.scrollTo(0, 0)

    this.loading = false
    this.notFound = !this.page
    if (this.notFound) { return }

    const p = this.page!

    this.titleService.setTitle(p.metaTitle);
    this.metaService.addTags([
      {name: 'keywords', content: p.metaTags },
      {name: 'description', content: p.metaDescription },
      {name: 'robots', content: 'index, follow'}
    ]);
  }

}