export const pageLinks =   [
    {
      icon: '/assets/home/how-01.svg',
      title: 'IT Consultancy',
      link: '/it-consultancy'
    },
    {
      icon: '/assets/home/how-02.svg',
      title: 'Custom-made Software',
      link: '/bespoke-software-development'
    },
    {
      icon: '/assets/home/how-03.svg',
      title: 'Better Workflows & Smarter applications',
      link: '/smarter-applications-and-workflow-automation'
    },
    {
      icon: '/assets/home/how-04.svg',
      title: 'Integrate IT Systems',
      link: '/systems-integration-erp-software'
    },
    {
      icon: '/assets/home/how-05.svg',
      title: 'Outsource IT Management',
      link: '/it-outsourcing'
    }
  ]