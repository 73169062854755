<div class="lottie-box"
    observeIntersection
    [intersectionSteps]='2'
    (onAppearing)="play()"
    (didDisappear)="stop()">
</div>
<ng-lottie
    (animationCreated)="animationCreated($event)"
    [options]="animOptions"
    [styles]="animStyles"
    ></ng-lottie>