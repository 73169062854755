import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { HttpClientModule } from '@angular/common/http'

import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
// import { MatCardModule } from '@angular/material/card'
// import { MatButtonModule } from '@angular/material/button'
// import { MatExpansionModule } from '@angular/material/expansion'
// import { MatSelectModule } from '@angular/material/select'
// import { MatDatepickerModule } from '@angular/material/datepicker'
// import { MatNativeDateModule } from '@angular/material/core'

import { NavbarComponent } from './navbar/navbar.component'
import { DynamicFormQuestionComponent } from './dynamic-from/dynamic-form-question.component'
import { DynamicFormComponent } from './dynamic-from/dynamic-form.component';

const components = [
  // @components
  NavbarComponent,
  DynamicFormComponent,
  DynamicFormQuestionComponent,
]

const modules = [
  // @angular
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  HttpClientModule,
  // @material
  MatFormFieldModule,
  MatInputModule,
  // MatCardModule,
  // MatButtonModule,
  // MatExpansionModule,
  // MatSelectModule,
  // MatDatepickerModule,
  // MatNativeDateModule
  MatProgressSpinnerModule,

]
@NgModule({
  declarations: [...components],
  imports: [...modules],
  exports: [...components, ...modules],
  entryComponents: [],
  providers: [
    // MatDatepickerModule, 
    // MatNativeDateModule
  ],
})
export class SharedModule { }
