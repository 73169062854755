<div id="head">
    <section class="top">
        <div class="text">
            <div class="icon" [inlineSVG]="'/assets/pages/bespoke-hexagon.svg'"></div>
            <h1>{{ page.head.title }}</h1>
            <p *ngFor="let pg of page.head.p" 
            class="justify larger"
            [innerHtml]="pg"></p>
        </div>
    </section>
</div>

<div class="A-container">
    <section id="A">
        <div class="case-study">
            <ng-lottie-autoplay
                src="/assets/success-squares.json"
                class="squares"
            ></ng-lottie-autoplay>
            <case-study-box [data]="page.caseStudy"></case-study-box>
        </div>
        <div class="text">
            <h2 [innerHtml]="page.sectionA.title"></h2>
            <p *ngFor="let pg of page.sectionA.p" 
                class="justify"
                [innerHtml]="pg"></p>
        </div>
        <div class="s2"></div>
    </section>
</div>

<div id="band" >
    <p class="center caption" [innerHtml]="page.bandCaption"></p>
    <ul class="subtitle">
    <li *ngFor="let item of page.bandItems; let i = index">
        {{ item }}
        <ng-template [ngIf]="i + 1 < page.bandItems.length">&nbsp;•&nbsp;</ng-template>
    </li>
    </ul>
</div>

<div class="B-container">
    <section id="B">
        <div class="text">
            <h2 class="line-left" 
            [innerHtml]="page.sectionB.title"></h2>
            <p *ngFor="let pg of page.sectionB.p"
            class="justify"
            [innerHtml]="pg"></p>
        </div>
    </section>
    <section id="C">
        <div class="kbutton" routerLink="/" fragment="contact">{{ page.sectionB.button }}</div>
    </section>
</div>
