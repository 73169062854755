<div id="why">
    <section class="content">
        <div class="logo-and-text">
            <div class="bleft">
                <ng-lottie-autoplay 
                    class="logo-box"
                    src="/assets/logo.json">
                    </ng-lottie-autoplay>
            </div>
            <div id="text">
                <h1>Why KaapiSoft?</h1>
                <p>The really short answer to the question is that KaapiSoft can help you deliver your IT projects <span class="underline">effectively and efficiently</span> – meaning achieving your goals in less time and with less budget.</p>
                <p>We are a <span class="underline">lean and agile</span> team with an on-demand pool of resources that can quickly scale up or down according to your needs while working closely with your teams as if we are part of it.</p>
                <p><span class="underline">Successful outsourcing</span> doesn’t need to be distant or difficult!</p>
            </div>
            <div class="bright"></div>     
            <br>
        </div>
        <div class="kbutton" routerLink="/bespoke-software-development">FIND OUT MORE</div>
    </section>
</div>