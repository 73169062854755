<div class="page-container">
    <div id="head">
        <section class="grid">
            <div id="icon" [inlineSVG]="page.head.icon"></div>
            <h1 id="title"
                [innerHtml]="page.head.title"></h1>
            <div id="text">
                <p *ngFor="let pg of page.head.p" 
                    class="justify"
                    [innerHtml]="pg"></p>
            </div>
        </section>
    </div>

    <section id="A">
        <div class="items">
            <div class="card titleA">
                <h2 class="txt"
                    [innerHtml]="page.sectionA.title"></h2>
            </div>
            <div *ngFor="let i of page.sectionA.items" 
                class="card">
                <div class="icon" [inlineSVG]="i.icon"></div>
                <div class="subtitle" [innerHtml]="i.title"></div>
                <p class="justify" [innerHtml]="i.text"></p></div>
        </div>
    </section>

    <div id="B">
        <div class="kbutton" routerLink="/" fragment="contact">{{ page.sectionB.button }}</div>
    </div>

</div>
