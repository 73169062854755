// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // API: 'https://kaapisoft-web-zty4r6urlq-ew.a.run.app/api',
  // API: 'https://new.kaapisoft.com/api',
  API: 'https://hubspot-api-bridge-lcvw2dhe6a-ew.a.run.app/api',
  wp: 'https://wp-api.kaapisoft.com'
  // wp: 'http://localhost:3000'
  // API: 'http://localhost:3000/api',
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
