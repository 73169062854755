import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core'
import { skip } from 'rxjs/operators'

import { TransitionService } from './transition.service'

@Component({
  selector: 'three-transition',
  templateUrl: './transition.component.html',
  styleUrls: ['./transition.component.scss']
})
export class TransitionComponent implements OnInit, OnChanges {

  @ViewChild('rendererCanvas', { static: true })
  public rendererCanvas!: ElementRef<HTMLCanvasElement>

  @Input() images?: string[]
  @Input() currentIndex = 0

  @Output() transitionFinished = new EventEmitter()
  @Output() texturesLoaded = new EventEmitter<boolean>()

  fragment = `
  uniform float time;
  uniform float progress;
  uniform float width;
  uniform float scaleX;
  uniform float scaleY;
  uniform float transition;
  uniform float radius;
  uniform float intensity;
  uniform sampler2D texture1;
  uniform sampler2D texture2;
  uniform sampler2D displacement;
  uniform vec4 resolution;
  varying vec2 vUv;
  void main()	{
    vec2 newUV = (vUv - vec2(0.5))*resolution.zw + vec2(0.5);
      vec4 d1 = texture2D(texture1, newUV);
      vec4 d2 = texture2D(texture2, newUV);
      float displace1 = (d1.r + d1.g + d1.b)*0.33;
      float displace2 = (d2.r + d2.g + d2.b)*0.33;

      vec4 t1 = texture2D(texture1, vec2(newUV.x, newUV.y + progress * (displace2 * intensity)));
      vec4 t2 = texture2D(texture2, vec2(newUV.x, newUV.y + (1.0 - progress) * (displace1 * intensity)));
      gl_FragColor = mix(t1, t2, progress);
  }
  `

  uniforms = {
    intensity: { value: 0.3, type: 'f', min: 0., max: 2 },
  }

  constructor(private tServ: TransitionService) {
  }

  ngOnInit(): void {
    if (this.images === undefined) {
      console.error('no images are set')
      return
    }
    this.tServ.setScene(this.rendererCanvas, this.images, this.fragment, this.uniforms)
    this.tServ.transitionFinished$.subscribe(t => this.transitionFinished.emit())
    this.tServ.texturesLoaded$.pipe(skip(1)).subscribe(t => this.texturesLoaded.emit(t))
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentIndex && changes.currentIndex.firstChange === false) {
      // console.log();
      // this.tServ.next();
      this.tServ.goTo(changes.currentIndex.currentValue)
    }
  }

  startEngine(percent: number): void {
    this.tServ.resume()
  }

  pauseEngine(): void {
    this.tServ.pause()
  }


  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.hidden){
      this.pauseEngine()
    } else {
      this.startEngine(100)
    }
  }

}
