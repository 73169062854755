import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { QuestionBase } from './question-base'
import { QuestionControlService } from './question-control.service'

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  providers: [QuestionControlService]
})
export class DynamicFormComponent implements OnInit {

  @Input() questions: QuestionBase<string>[] = []
  @Input() submitButton: string = 'Submit'

  @Output() formSubmitted = new EventEmitter<any>()

  form?: FormGroup
  payLoad = ''

  constructor(private qcs: QuestionControlService) { }

  ngOnInit(): void {
    this.form = this.qcs.toFormGroup(this.questions)
  }

  onSubmit(): void {

    this.form?.updateValueAndValidity()
    if (!this.form?.valid) return

    if (!!this.form) {
      this.payLoad = JSON.stringify(this.form.getRawValue())

      const hubspotFormFields = [
        // {
        //   "name": "email",
        //   "value": "ian@server.com"
        // },
        // {
        //   "name": "firstname",
        //   "value": "Ian"
        // },
        // {
        //   "name": "lastname",
        //   "value": "G"
        // },
        // {
        //   "name": "message",
        //   "value": "Hello World!"
        // }
      ]

      for (const control in this.form.controls) {
        if (Object.prototype.hasOwnProperty.call(this.form.controls, control)) {
          const element = this.form.controls[control]

          hubspotFormFields.push({
            name: control,
            value: element.value
          })

        }
      }



      this.formSubmitted.emit(hubspotFormFields)
    }
  }
}
