import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

import { EMPTY, Observable, of } from 'rxjs'
import { catchError } from 'rxjs/operators'

import { setAuthHeaders } from '../helpers/set-auth-headers'
import { HubspotGetFormResponse, HubspotGetFormsResponse } from '../../interfaces/hubspot-response'
import { environment } from 'ui/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class HubspotService {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getForms(token = ''): Observable<HubspotGetFormsResponse> {

    const url = environment.API + '/hubspot/forms/'
    const headers = setAuthHeaders(token)
    return this.http.get<HubspotGetFormsResponse>(url, { headers })
      .pipe(catchError((error) => {
        console.error({ error })

        throw error
      }))


    // return this.http.post<{
    //   token?: string
    //   user_display_name?: string
    //   user_email?: string
    //   user_nicename?: string
    // }>(`hubspot/submitForm`, data)
    //   .pipe(catchError((error) => {
    //     console.log({ error })

    //     return of({
    //       token: undefined,
    //       user_display_name: undefined,
    //       user_email: undefined,
    //       user_nicename: undefined
    //     })
    //   }))
  }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getForm(formId: string, token = ''): Observable<HubspotGetFormResponse> {

    const headers = setAuthHeaders(token)
    const url = environment.API + '/hubspot/forms/' + formId
    return this.http.get<HubspotGetFormResponse>(url, { headers })
      .pipe(catchError((error) => {
        console.error({ error })

        throw error
      }))


    // return this.http.post<{
    //   token?: string
    //   user_display_name?: string
    //   user_email?: string
    //   user_nicename?: string
    // }>(`hubspot/submitForm`, data)
    //   .pipe(catchError((error) => {
    //     console.log({ error })

    //     return of({
    //       token: undefined,
    //       user_display_name: undefined,
    //       user_email: undefined,
    //       user_nicename: undefined
    //     })
    //   }))
  }
  /**
   * Returns an Observable for the HTTP POST request for the JSON resource.
   */
  postForm(postData: {
    portalId: number;
    formId: string;
    data: {
      submittedAt?: number;
      fields: {};
    };
  }): Observable<{
    message: string;
    responseCode: number;
    data: {
      submitRes: any;
    };
    error: any
  }> {

    const url = environment.API + '/hubspot/submitForm'

    return this.http.post<{
      message: string;
      responseCode: number;
      data: {
        submitRes: any;
      };
      error: any;
    }>(url, postData)
      .pipe(catchError((error) => {
        console.log({ error })

        return EMPTY
      }))
  }

}
