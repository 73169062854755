<div id="head">
    <section class="top">
        <div class="text">
            <div class="icon" [inlineSVG]="page.head.icon"></div>
            <h1 class="line-left"
                [innerHtml]="page.head.title"></h1>
            <p *ngFor="let pg of page.head.p" 
            class="justify"
            [innerHtml]="pg"></p>
        </div>
    </section>
</div>

<div class="A-container">
    <section id="A">
        <div class="case-study">
            <ng-lottie-autoplay
                src="/assets/success-squares.json"
                class="squares"
            ></ng-lottie-autoplay>
            <case-study-box 
                [data]="page.caseStudy"
                boxStyle="teal">
            </case-study-box>
        </div>
        <div class="text">
            <p *ngFor="let pg of page.sectionA.p1" 
                class="justify"
                [innerHtml]="pg"></p>

            <h3 class="line-left" 
                [innerHtml]="page.sectionA.p2"></h3>

            <p *ngFor="let pg of page.sectionA.p3" 
                class="justify"
                [innerHtml]="pg"></p>

            <div class="kbutton last" routerLink="/" fragment="contact">GET IN TOUCH</div>

        </div>
    </section>
</div>