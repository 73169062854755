export const page = {
    head: {
        title: "Bespoke Software Development",
        p: [ 
            `Developing bespoke software is at the core of 
            what we do and specialize in, so we can definitely 
            offer a lot of <u>professional help</u> in this area.`,
            
            `Whether you want to upgrade your legacy systems 
            so that they grow with your business, start a new 
            project, or develop a better system for one of your 
            teams, we are ready to come in and offer <u>our expertise</u>.`
        ]
    },
    caseStudy: {
        logo: '/assets/home/logos/go-plc.svg',
        text: 'See the Go Plc case study',
        url: '/assets/case-study/CaseStudy_GoPlc.pdf'
    },
    sectionA: {
        title: `As a software development company, we have worked
        with many satisfied customers in this area.`,
        p: [
            `We’ve developed <u>bespoke software</u> ranging from simple
            system interfaces to very intricate and sophisticated 
            software engineering that have improved the operations 
            and commercial viability of many businesses.`,
        
            `The average business will go for off-the-shelf software
            products whether it’s for their accounts, sales & marketing,
            customer support and other business units within the company.
            Smarter businesses go for smarter, long-term sustainable 
            and efficient solutions such as investing in a <u>custom-made
            software application</u>.`
        ]
    },
    bandCaption: `Examples of Bespoke software that you might be interested in: `,
    bandItems: [ "CRM Software", "Payroll Software", "POS Software", 
                  "ERP Software", "HR Software", "Rental Property Management software",
                  "Project Management Software", "Warehouse Management Software",
                  "Database Management Software"
                ],
    sectionB: {
        title: `Not decided if you should go for Bespoke Solutions?`,
        p: [
            `Every business faces its own unique challenges whether it’s 
            because of its organizational setup or because of external 
            forces such as the nature of its niche market, its competition,
             or the technology it uses.`,

            `Off-the-shelf products such as purchasing a CRM or accountancy
             software product are made to fit most businesses in general.
              But there will always be a trade-off there, or something 
              that has got to be sacrificed such as efficiency because 
              it doesn’t suit completely the particular needs and business 
              context of the organization.`,            

            `A bespoke software solution is one that is made specifically
             to fit perfectly with the needs and challenges your business
              is facing.`,

            `The benefits accompanying custom-built software are usually
             very business-critical when seen in the long run because 
             they usually entail cost-saving in otherwise wasted billable 
             time and other overheads, training costs, reduce human error,
              increase efficiency and giving an edge over competition.`
        ],
        button: 'GET IN TOUCH'
    },
    meta: {
        title: `Bespoke Software Development | Software Engineering | Custom Applications `,
        description: `Bespoke software development company in Malta - Developing bespoke software is at the core of what we do and specialize in, so we can definitely offer a lot of professional help in this area. Whether you want to upgrade your legacy systems so that they grow with your business, start a new project, or develop a better system for one of your… Continue reading Bespoke Software Development`,
        keywords: `
            software engineering,
            rapid application development,
            bespoke software,
            lean development,
            software application development,
            software application development malta,
            custom software,
            custom software development,
            custom software development malta,
            crm software,
            crm software malta, 
            project management software,
            payroll software,
            inventory management software,
            pos software,
            HR software,
            warehouse management software,
            facility management software,
            rental property management software,
            database management software`
    }
}
