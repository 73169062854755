import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { HubspotService } from './hubspot.service'

import { HubspotForm } from '../../interfaces/hubspot-form'
import { TextboxQuestion } from 'ui/app/shared/dynamic-from/question-textbox'
import { DropdownQuestion } from 'ui/app/shared/dynamic-from/question-dropdown'
import { TextAreaQuestion } from 'ui/app/shared/dynamic-from/question-textarea'
import { DatePickerQuestion } from 'ui/app/shared/dynamic-from/question-date-picker'

@Injectable({
  providedIn: 'root'
})
export class HubspotStore {

  constructor(
    private hubspotService: HubspotService
  ) { }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getForms(token?: string): Observable<HubspotForm[]> {

    return this.hubspotService.getForms(token)
      .pipe(map((response) => {
        if (response.responseCode === 200) {
          return response.data.hubspotForms.theForms.map((f) => {

            const {
              portalId,
              guid,
              name,
              cssClass,
              submitText,
              formFieldGroups,
              inlineMessage,
              campaignGuid,
              createdAt,
              updatedAt,
              parentId,
              style,
              isPublished,
              customUid
            } = f

            const controls = formFieldGroups.map((fg) => {


              const stringFields = fg.fields
                .filter((field) => field.type === 'string')
              const selectFields = fg.fields
                .filter((field) => field.type === 'enumeration')
              const datePickerFields = fg.fields
                .filter((field) => field.type === 'date')
              const unhandledFields = fg.fields
                .filter((field) => field.type !== 'string' && field.type !== 'enumeration' && field.type === 'date')

              unhandledFields.length && console.log({ unhandledFields })

              const stringControls = stringFields.map((field, i) => {
                const order = i + 1

                const QuestionClass = field.fieldType === 'textarea' ? TextAreaQuestion : TextboxQuestion

                const control = new QuestionClass({
                  key: field.name,
                  label: field.label,
                  value: !!field.defaultValue ? field.defaultValue : null,
                  required: field.required,
                  type: field.name.indexOf('email') > -1 ? 'email' : '',
                  placeholder: field.placeholder,
                  order: field.displayOrder > -1 ? field.displayOrder : order
                })
                return control
              })

              const selectControls = selectFields.map((field, i) => {
                const order = i + 1

                const control = new DropdownQuestion({
                  key: field.name,
                  label: field.label,
                  value: !!field.defaultValue ? field.defaultValue : null,
                  required: field.required,
                  type: field.name.indexOf('email') > -1 ? 'email' : '',
                  placeholder: field.placeholder,
                  order: field.displayOrder > -1 ? field.displayOrder : order,
                  options: (field.options || []).map((option) => {
                    return {
                      key: option.value,
                      value: option.label
                    }
                  })
                })
                return control
              })

              const datePickerControls = datePickerFields.map((field, i) => {
                const order = i + 1

                const control = new DatePickerQuestion({
                  key: field.name,
                  label: field.label,
                  value: !!field.defaultValue ? field.defaultValue : null,
                  required: field.required,
                  type: field.name.indexOf('email') > -1 ? 'email' : '',
                  placeholder: field.placeholder,
                  order: field.displayOrder > -1 ? field.displayOrder : order,
                  options: (field.options || []).map((option) => {
                    return {
                      key: option.value,
                      value: option.label
                    }
                  })
                })
                return control
              })

              return stringControls.concat(selectControls).concat(datePickerControls)

            })
              .reduce((accumulator, value) => accumulator.concat(value), [])
              .sort((a, b) => a.order - b.order)

            // controls.push(new DropdownQuestion({
            //   key: 'brave',
            //   label: 'Bravery Rating',
            //   options: [
            //     { key: 'solid', value: 'Solid' },
            //     { key: 'great', value: 'Great' },
            //     { key: 'good', value: 'Good' },
            //     { key: 'unproven', value: 'Unproven' }
            //   ],
            //   order: 7
            // }))

            return {
              portalId,
              guid,
              name,
              cssClass,
              submitText,
              formFieldGroups,
              inlineMessage,
              campaignGuid,
              createdAt,
              updatedAt,
              parentId,
              style,
              isPublished,
              customUid,
              controls
            }
          })
        } else {
          return []
        }


      }))
  }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getForm(formId: string, token?: string): Observable<HubspotForm | undefined> {

    return this.hubspotService.getForm(formId, token)
      .pipe(map((response) => {
        if (response.responseCode === 200) {
          const f = response.data.hubspotForm.theForm

          const {
            portalId,
            guid,
            name,
            cssClass,
            submitText,
            formFieldGroups,
            inlineMessage,
            campaignGuid,
            createdAt,
            updatedAt,
            parentId,
            style,
            isPublished,
            customUid
          } = f

          const controls = formFieldGroups.map((fg) => {


            const stringFields = fg.fields
              .filter((field) => field.type === 'string')
            const selectFields = fg.fields
              .filter((field) => field.type === 'enumeration')
            const datePickerFields = fg.fields
              .filter((field) => field.type === 'date')
            const unhandledFields = fg.fields
              .filter((field) => field.type !== 'string' && field.type !== 'enumeration' && field.type === 'date')

            unhandledFields.length && console.log({ unhandledFields })

            const stringControls = stringFields.map((field, i) => {
              const order = i + 1

              const QuestionClass = field.fieldType === 'textarea' ? TextAreaQuestion : TextboxQuestion

              const control = new QuestionClass({
                key: field.name,
                label: field.label,
                value: !!field.defaultValue ? field.defaultValue : null,
                required: field.required,
                type: field.name.indexOf('email') > -1 ? 'email' : '',
                placeholder: field.placeholder,
                order: field.displayOrder > -1 ? field.displayOrder : order
              })
              return control
            })

            const selectControls = selectFields.map((field, i) => {
              const order = i + 1

              const control = new DropdownQuestion({
                key: field.name,
                label: field.label,
                value: !!field.defaultValue ? field.defaultValue : null,
                required: field.required,
                type: field.name.indexOf('email') > -1 ? 'email' : '',
                placeholder: field.placeholder,
                order: field.displayOrder > -1 ? field.displayOrder : order,
                options: (field.options || []).map((option) => {
                  return {
                    key: option.value,
                    value: option.label
                  }
                })
              })
              return control
            })

            const datePickerControls = datePickerFields.map((field, i) => {
              const order = i + 1

              const control = new DatePickerQuestion({
                key: field.name,
                label: field.label,
                value: !!field.defaultValue ? field.defaultValue : null,
                required: field.required,
                type: field.name.indexOf('email') > -1 ? 'email' : '',
                placeholder: field.placeholder,
                order: field.displayOrder > -1 ? field.displayOrder : order,
                options: (field.options || []).map((option) => {
                  return {
                    key: option.value,
                    value: option.label
                  }
                })
              })
              return control
            })

            return stringControls.concat(selectControls).concat(datePickerControls)

          })
            .reduce((accumulator, value) => accumulator.concat(value), [])
            .sort((a, b) => a.order - b.order)

          // controls.push(new DropdownQuestion({
          //   key: 'brave',
          //   label: 'Bravery Rating',
          //   options: [
          //     { key: 'solid', value: 'Solid' },
          //     { key: 'great', value: 'Great' },
          //     { key: 'good', value: 'Good' },
          //     { key: 'unproven', value: 'Unproven' }
          //   ],
          //   order: 7
          // }))

          return {
            portalId,
            guid,
            name,
            cssClass,
            submitText,
            formFieldGroups,
            inlineMessage,
            campaignGuid,
            createdAt,
            updatedAt,
            parentId,
            style,
            isPublished,
            customUid,
            controls
          } as HubspotForm
          // })
        } else {
          return undefined
        }
      }))
  }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  postForm(data: {
    portalId: number,
    formId: string,
    data: {}
  }): Observable<{
    message: string;
    responseCode: number;
    data: {
      submitRes: any;
    };
    error: null;
  }> {

    const ddd = {
      portalId: +data.portalId,
      formId: data.formId,
      data: {
        // submittedAt: new Date(),
        fields: data.data
      }
    }
    return this.hubspotService.postForm(ddd)
  }

}
