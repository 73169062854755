import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { WPPageData } from 'ui/app/core/interfaces/wp-page-data';
import { caseStudies } from 'ui/app/_content/case-studies';
import { pageLinks } from 'ui/app/_content/page-links';

@Component({
  selector: 'site-footer',
  templateUrl: './site-footer.component.html',
  styleUrls: ['./site-footer.component.scss']
})
export class SiteFooterComponent implements OnInit {

  sections = []

  // @Input() pages: WPPageData[] | undefined

  pages = pageLinks
  caseStudies = caseStudies

  constructor(private router: Router) { }

  ngOnInit() {
  }

  async openHomeFragment(fragment: string) {
    this.router.onSameUrlNavigation = 'reload'
    await this.router.navigate(['/'], { fragment:  fragment })
  }

  async nav(url: string) {
    await this.router.navigate([url])
  }

  newTab(link:string) {
    window.open(link, '_blank')
  }

}