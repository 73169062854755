import { trigger, transition, style, animate, keyframes, query, stagger } from '@angular/animations'

export const inOutAnimation =
  trigger('inOutAnimation',
    [
      transition(
        'void => *', []
      ),
      transition(
        ':enter',
        [
          style({ opacity: 0 }),
          animate(
            '200ms cubic-bezier(.11,.61,.57,.99)',
            style({ opacity: 1 })
          )
        ]
      ),
      transition(
        ':leave',
        [
          style({ transform: 'translateY(0)' }),
          animate(
            '200ms ease-out',
            style({ transform: 'translateY(-100%)' })
          )
        ]
      )
    ]
  )