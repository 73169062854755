import {
  BrowserModule,
  HammerModule,
  HAMMER_GESTURE_CONFIG,
} from '@angular/platform-browser';
import { HammerGestureConfig } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { LottieModule } from 'ngx-lottie';
import player, { LottiePlayer } from 'lottie-web';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SliderComponent } from './components/home/slider/slider.component';
import { TransitionComponent } from './engine/transition/transition.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SmoothHeightComponent } from './shared/smooth-height.component';
import { WhyComponent } from './components/home/why/why.component';
import { HomeComponent } from './components/home/home.component';

import { MouseWheelDirective } from './shared/mousewheel.directive';

import { HowComponent } from './components/home/how/how.component';
import { ObserveIntersectionDirective } from './shared/intersection-observer.directive';
import { SuccessComponent } from './components/home/success/success.component';
import { ContactComponent } from './components/home/contact/contact.component';
import { DynamicPagesComponent } from './components/dynamic-pages/dynamic-pages.component';
import { Page404Component } from './components/page404/page404.component';
import { SiteFooterComponent } from './components/site-footer/site-footer.component';
import { SiteHeaderComponent } from './components/site-header/site-header.component';
import { NgLottieAutoplayComponent } from './shared/ng-lottie-autoplay/ng-lottie-autoplay.component';
import { BespokeSoftwareDevelopmentComponent } from './components/pages/bespoke-software-development/bespoke-software-development.component';
import { CaseStudyBoxComponent } from './components/pages/case-study-box/case-study-box.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { SystemsIntegrationComponent } from './components/pages/systems-integration/systems-integration.component';
import { SmarterApplicationsComponent } from './components/pages/smarter-applications/smarter-applications.component';
import { ItConsultancyComponent } from './components/pages/it-consultancy/it-consultancy.component';
import { ItOutstaffingServicesComponent } from './components/pages/it-outstaffing-services/it-outstaffing-services.component';
import { ItOutsourcingComponent } from './components/pages/it-outsourcing/it-outsourcing.component';
import { AboutUsComponent } from './components/pages/about-us/about-us.component';
import { MenuComponent } from './components/menu/menu.component';
import { PreloaderComponent } from './components/preloader/preloader.component';
import { PreloaderService } from './components/preloader/preloader.service';

import { FacebookMessengerComponent } from './components/facebook-messenger/facebook-messenger.component';
import { FacebookModule } from '@jemys89/ngx-facebook';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';

type HammerOverrides = {
  [gesture: string]: Object;
};

export class MyHammerConfig extends HammerGestureConfig {
  overrides: HammerOverrides = {
    swipe: { direction: Hammer.DIRECTION_ALL }, // Override default settings
  };
}

@NgModule({
  declarations: [
    AppComponent,
    TransitionComponent,

    NgLottieAutoplayComponent,
    SmoothHeightComponent,
    MouseWheelDirective,
    ObserveIntersectionDirective,

    HomeComponent,
    WhyComponent,
    SliderComponent,
    HowComponent,
    SuccessComponent,
    ContactComponent,

    Page404Component,
    SiteFooterComponent,
    SiteHeaderComponent,

    DynamicPagesComponent,

    CaseStudyBoxComponent,
    BespokeSoftwareDevelopmentComponent,
    SystemsIntegrationComponent,
    SmarterApplicationsComponent,
    ItConsultancyComponent,
    ItOutstaffingServicesComponent,
    ItOutsourcingComponent,
    AboutUsComponent,
    MenuComponent,
    PreloaderComponent,
    FacebookMessengerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HammerModule,
    LottieModule.forRoot({ player: playerFactory }),
    InlineSVGModule.forRoot(),
    NgScrollbarModule,
    CoreModule,
    SharedModule,
    FacebookModule.forRoot(),

    NgxGoogleAnalyticsModule.forRoot('UA-201877585-2'),
    NgxGoogleAnalyticsRouterModule,
  ],
  providers: [
    PreloaderService,
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function playerFactory(): LottiePlayer {
  return player;
}
