import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { page } from '../../../_content/page-it-outsourcing';

@Component({
  selector: 'app-it-outsourcing',
  templateUrl: './it-outsourcing.component.html',
  styleUrls: ['./it-outsourcing.component.scss']
})
export class ItOutsourcingComponent implements OnInit {

  page = page

  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(page.meta.title)
    this.metaService.updateTag(
      { name: 'keywords', content: page.meta.keywords })
    this.metaService.updateTag(
      { name: 'description', content: page.meta.description })
    this.metaService.updateTag(
      { name: 'robots', content: 'index, follow' })
  }


}
