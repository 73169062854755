<div id="sections">
<!--
    mouseWheel 
     (mouseWheelUp)="mouseWheelUpFunc($event)" 
    (mouseWheelDown)="mouseWheelDownFunc($event)"> -->
    <ng-container *ngTemplateOutlet="sectionsTemplate"></ng-container>
</div>
<!-- <div id="sections" *ngIf="!isHoriz()">
    <ng-container *ngTemplateOutlet="sectionsTemplate"></ng-container>
</div> -->
<ng-template #sectionsTemplate>
    <div #sectionSlider>
        <home-slider></home-slider>
    </div>
    <div #sectionWhy>
        <home-why ></home-why>
    </div>
    <div #sectionHow>
        <home-how></home-how>
    </div>
    <div #sectionSuccess>
        <home-success></home-success>
    </div>
    <div #sectionContact>
        <home-contact></home-contact>
    </div>
</ng-template>