<ng-scrollbar #scrollbarRef 
    [track]='track'
    [scrollAuditTime]="20"
    [autoHeightDisabled]='true'
    [autoWidthDisabled]='true'
    trackClass="mscroll"
    class="kscroll">
    <router-outlet></router-outlet>
    <site-footer></site-footer>
</ng-scrollbar>
<div [@inout] 
     class="backToTop" 
     *ngIf="showBackToTop"
     (click)="backToTop()">
     <div inlineSVG="/assets/arrow-up.svg"></div>
</div>
<app-facebook-messenger></app-facebook-messenger>
<app-menu></app-menu>
<app-preloader></app-preloader>