export const caseStudies = [
{
    link: '/assets/case-study/CaseStudy_GoPlc.pdf',
    title: 'GO plc'    
},
{
    link: '/assets/case-study/CaseStudy_BMIT.pdf',
    title: 'BMIT'    
},
{
    link: '/assets/case-study/CaseStudy_TastePlease.pdf',
    title: 'TastePlease'    
},
{
    link: '/assets/case-study/CaseStudy_Cleverbit.pdf',
    title: 'Cleverbit'    
},
{
    link: '/assets/case-study/CaseStudy_See3D.pdf',
    title: 'See3D'    
},
{
    link: '/assets/case-study/CaseStudy_BetStudios.pdf',
    title: 'bet studios'    
},
] 
