import { AfterContentInit, Component, Input, NgZone, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/src/symbols';

@Component({
  selector: 'ng-lottie-autoplay',
  templateUrl: './ng-lottie-autoplay.component.html',
  styleUrls: ['./ng-lottie-autoplay.component.scss']
})
export class NgLottieAutoplayComponent implements OnInit, AfterContentInit {

  @Input() src: string = ''
  // @Input() lclass: string = ''
  private animationItem?: AnimationItem

  animOptions: AnimationOptions = {
    path: this.src
  }
 
  animStyles: Partial<CSSStyleDeclaration> = {
    position: 'absolute',
    left: '0',
    right: '0',
    top: '0',
    bottom: '0'
  }

  constructor(private ngZone: NgZone) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.stop()
    this.animOptions = {
      path: this.src
    }
  }

  animationCreated(animationItem: AnimationItem): void {
    this.animationItem = animationItem;
  }
  stop(): void { this.ngZone.runOutsideAngular(() => this.animationItem?.stop()); } 
  play(): void { this.ngZone.runOutsideAngular(() => this.animationItem?.play()); }

}
