import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { page } from '../../../_content/page-bespoke-software-development'

@Component({
  selector: 'app-bespoke-software-development',
  templateUrl: './bespoke-software-development.component.html',
  styleUrls: ['./bespoke-software-development.component.scss']
})
export class BespokeSoftwareDevelopmentComponent implements OnInit {

  page = page

  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(page.meta.title)
    this.metaService.updateTag(
      { name: 'keywords', content: page.meta.keywords })
    this.metaService.updateTag(
      { name: 'description', content: page.meta.description })
    this.metaService.updateTag(
      { name: 'robots', content: 'index, follow' })
  }

}
