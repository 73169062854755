import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { pageLinks } from 'ui/app/_content/page-links';
import { inOutAnimation } from './animations';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    inOutAnimation
  ]
})
export class MenuComponent implements OnInit {

  constructor(private router: Router,
              private location: Location) { }

  show = false
 
  right = pageLinks

  ngOnInit(): void {
  }

  async openHomeFragment(fragment: string) {
    this.router.onSameUrlNavigation = 'reload'
    const navigated = await this.router.navigate(['/'], { fragment:  fragment })
    await this.delay(300)
    this.show = false
  }

  openBlog() {
    window.open("https://www.kaapisoft.com/blog", "_blank");
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
  }

  async nav(url: string) {
    this.router.navigate([url])
    await this.delay(300)
    this.show = false
  }

  toggle(): void {
    this.show = !this.show
  }

}
