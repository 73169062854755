import { Component, OnInit } from '@angular/core';
import { PreloaderService } from 'ui/app/components/preloader/preloader.service';
import { inOutAnimation } from './animations';

@Component({
  selector: 'app-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  animations: [ inOutAnimation ]
})
export class PreloaderComponent implements OnInit {

  constructor(public preloaderService: PreloaderService) { }

  ngOnInit(): void {
    // this.preloaderService.spinner = true
  }

}
