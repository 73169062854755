import { Component, OnInit } from '@angular/core';
import { FacebookService, InitParams } from '@jemys89/ngx-facebook';

@Component({
  selector: 'app-facebook-messenger',
  templateUrl: './facebook-messenger.component.html',
  styleUrls: ['./facebook-messenger.component.scss']
})
export class FacebookMessengerComponent implements OnInit {

  constructor(private facebookService: FacebookService) { }

  ngOnInit(): void {
    this.initFacebookService();
  }

  private initFacebookService(): void {
    const initParams: InitParams = { 
      xfbml:true, 
      version: 'v10.0'
    };
    this.facebookService.init(initParams);
  }

}
