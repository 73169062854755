<footer id="footer">
    <div class="content">
        <div class="row">
            <div class="col">
                <div class="kaapi-logo-2" inlineSVG="/assets/kaapisoft-logo.svg"></div>
            </div>
            <div class="col contact">
                <div class="subtitle" routerLink='/' fragment='contact'>About us</div>
                <div class="row">
                    <div class="link" (click)="nav('about-us')">Who We Are</div>
                </div>
                <div class="row">
                    <div class="link" (click)="openHomeFragment('contact')">Contact us</div>
                </div>
                <div class="row">
                    <a href="http://www.kaapisoft.com/blog" target="_blank">
                        Blog
                    </a>
                </div>
                <div class="row">
                    <ul class="social-icon-list">
                        <li>
                            <a href="https://www.facebook.com/kaapisoft" target="_blank">
                                <img src="/assets/s/facebook-footer.svg" alt="facebook-footer">
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/kaapisoft" target="_blank">
                                <img src="/assets/s/linkedIn-footer.svg" alt="linkedIn-footer"> 
                            </a>
                        </li>
                    </ul>
                    
                </div>
            </div>
            <div class="col">
                <div class="subtitle">How we can help</div>         
                <ng-container *ngFor="let p of pages">
                    <div><a href="{{ p.link }}" [innerHtml]='p.title'></a></div>
                </ng-container>
            </div>
            <div class="col">
                <div class="subtitle">Case studies</div>
                <div *ngFor="let cs of caseStudies">
                    <a href="{{ cs.link }}" target="_blank">{{ cs.title }}</a>
                </div>
            </div>
            <!-- <div class="col contact">
                <div class="subtitle" routerLink='/' fragment='contact'>Contact us</div>
                <div class="row" (click)="newTab('tel:+356 7904 6188')">
                    <img src="/assets/contact-phone.svg" />
                    <strong>+356 7904 6188</strong>
                </div>
                <div class="row" (click)="newTab('mailto:hello@kaapisoft.com')">
                <img src="/assets/contact-email.svg" />
                hello@kaapisoft.com
                </div>
                <div class="row" (click)="newTab('https://www.facebook.com/kaapisoft/')">
                <img src="/assets/contact-facebook.svg" />
                Facebook
                </div>
                <div class="row" (click)="newTab('https://www.linkedin.com/company/kaapisoft/')">
                <img src="/assets/contact-linkedin.svg" />
                LinkedIn
                </div>
                <div class="row"><div class="space">&nbsp;</div></div>
                <div class="row">
                    <img src="/assets/contact-globe.svg" />
                    <strong>KaapiSoft Ltd</strong><br/>
                    NXR3651 Naxxar<br/>
                    Malta
                </div>
            </div> -->
        </div>
        <div class="copy">© 2022 KaapiSoft LTD</div>
    </div>
</footer>