<div id="bkg"
    observeIntersection
    [intersectionSteps]='20'
    (onAppearing)="appearing()"
    (didDisappear)="disappearing()">
    <three-transition 
    [images]="images" 
    [currentIndex]="imageIndex"
    (texturesLoaded)="hidePreloader($event)"
    (transitionFinished)="afterTransition()"></three-transition>
    <div class="container">
        <div class="kaapi-logo" 
             [inlineSVG]="'/assets/logo.svg'"> </div>
        <div class="centered" 
             *ngIf="slide==0 || slide==4"
             [@inOutAnimation]>
        <div class="capsule">
            <smooth-height [trigger]="slide">
                <div class="title">
                    The Craft of Custom Applications
                    for Specific Business Goals 
                </div>
                <div class="subtitle" *ngIf="slide==0">
                    <div class="block">
                        <div class="underline">You paint the vision</div>,&nbsp;
                    </div>
                    <div class="block">we develop and deliver it</div>
                </div>
                <div class="subtitle" *ngIf="slide==4">
                    <div class="block">Smart applications built on&nbsp;</div>
                    <div class="block">
                        <span class="underline">your uniqueness</span>
                    </div>
                </div>
            </smooth-height>
        </div>
        </div>

        <div 
            class="centered" 
            *ngIf="slide==1 || slide==5"
            [@inOutAnimation]>
            <div class="capsule">
                <smooth-height [trigger]="slide">
                    <div class="title">
                        The Art of Outsourcing IT without the Pain Points  
                    </div>
                    <div class="subtitle" *ngIf="slide==1">
                        <div class="block">
                            We can support part of <div class="underline">your project</div>
                        </div>
                        <div class="block">or manage it entirely</div>
                    </div>
                    <div class="subtitle" *ngIf="slide==5">
                        <div class="block">Software development&nbsp;|&nbsp;</div>
                        <div class="block">Systems Integration&nbsp;|&nbsp;</div>
                        <div class="block">R&D&nbsp;|&nbsp;</div>
                        <div class="block">Consultation</div>
                    </div>
                </smooth-height>
            </div>
        </div>

        <div 
            class="centered" 
            *ngIf="slide==2 || slide==6"
            [@inOutAnimation]>
            <div class="capsule dark">
                <smooth-height [trigger]="slide">
                    <div class="title">
                        The Magic of Solution Based Software Products  
                    </div>
                    <div class="subtitle" *ngIf="slide==2">
                        <div class="block">Some people offer products.&nbsp;</div>
                        <div class="block">Some offer solutions.&nbsp;</div><br>
                        <div class="block"><span class="underline">We deliver both</span></div>
                    </div>
                    <div class="subtitle" *ngIf="slide==6">
                        <div class="block"><span class="underline">Let us help you</span></div>
                        <div class="block">&nbsp;develop solutions into products</div>
                    </div>
                </smooth-height>
            </div>
        </div>

        <div 
            class="centered" 
            *ngIf="slide==3 || slide==7"
            [@inOutAnimation]>
            <div class="capsule dark">
                <smooth-height [trigger]="slide">
                    <div class="title">
                        The Science of Mixing the Right Technology Stack 
                    </div>
                    <div class="subtitle" *ngIf="slide==3">
                        <div class="block">We have <span class="underline">talented minds with skills</span> across a board of technologies</div>
                    </div>
                    <div class="subtitle" *ngIf="slide==7">
                        <div class="block">PHP | Node.js | .net framework | angular js</div>
                    </div>
                </smooth-height>
            </div>
        </div>
    </div>
</div>