import { Component, Input } from '@angular/core'
import { FormGroup } from '@angular/forms'

import { QuestionBase } from './question-base'

@Component({
  selector: 'app-question',
  templateUrl: './dynamic-form-question.component.html'
})
export class DynamicFormQuestionComponent {

  @Input() question?: QuestionBase<string>
  @Input() form?: FormGroup

  get isValid(): boolean {
    if (this.form && this.question) {
      return !this.form.controls[this.question.key].touched || this.form.controls[this.question.key].valid
    }

    return false

  }
}
