<div id="dynamic-page" class="top-float"> 
  <site-header></site-header>
  <div class="wp-page" *ngIf="page">
    <div class="kaapi-logo" routerLink="/"></div>

    <h1 class="title" [innerHtml]="page.title"></h1>
    <div class="teal-separator"></div>
    <div [innerHtml]="page.content"></div>
  </div>
  <div *ngIf="notFound">
    <app-page404></app-page404>
  </div>
</div>