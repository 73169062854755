import { Directive, Output, HostListener, EventEmitter } from '@angular/core'

@Directive({ selector: '[mouseWheel]' })
export class MouseWheelDirective {
  @Output() mouseWheelUp = new EventEmitter()
  @Output() mouseWheelDown = new EventEmitter()

  @HostListener('mousewheel', ['$event']) onMouseWheelChrome(event: any) {
    this.mouseWheelFunc(event)
  }

  @HostListener('DOMMouseScroll', ['$event']) onMouseWheelFirefox(event: any) {
    this.mouseWheelFunc(event)
  }

  @HostListener('onmousewheel', ['$event']) onMouseWheelIE(event: any) {
    this.mouseWheelFunc(event)
  }

  mouseWheelFunc(event: any): void {
    const theEvent = window.event || event // old IE support
    const delta = Math.max(-1, Math.min(1, (theEvent.wheelDelta || -theEvent.detail)))
    if (delta > 0) {
      this.mouseWheelUp.emit(theEvent)
    } else if (delta < 0) {
      this.mouseWheelDown.emit(theEvent)
    }
    // for IE
    theEvent.returnValue = false
    // for Chrome and Firefox
    if (theEvent.preventDefault) {
      theEvent.preventDefault()
    }
  }
}
