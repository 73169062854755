import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core'
import { Meta, Title } from '@angular/platform-browser'
import { page } from 'ui/app/_content/page-home'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {


  @ViewChild('sectionSlider', { read: ElementRef }) sectionSlider!: ElementRef
  @ViewChild('sectionWhy', { read: ElementRef }) sectionWhy!: ElementRef
  @ViewChild('sectionHow', { read: ElementRef }) sectionHow!: ElementRef
  @ViewChild('sectionSuccess', { read: ElementRef }) sectionSuccess!: ElementRef
  @ViewChild('sectionContact', { read: ElementRef }) sectionContact!: ElementRef

  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    // this.track = "horizontal";
    // this.setWindowWidth(window.innerWidth)
    this.titleService.setTitle(page.meta.title)

    this.metaService.updateTag(
      { name: 'keywords', content: page.meta.keywords })
    this.metaService.updateTag(
      { name: 'description', content: page.meta.description })
    this.metaService.updateTag(
      { name: 'robots', content: 'index, follow' })
    
      // For testing purposes only. Feel free to remove.
      console.log('Updated: 2024-06-28 - Integrated CI/CD pipeline.')
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // this.setWindowWidth(event.target.innerWidth)
  }

  // private setWindowWidth(width: number) {
  //   if (width < 760) {
  //     this.track = 'vertical'
  //   } else {
  //     this.track = 'vertical'
  //     // this.track = 'horizontal'
  //   }
  // }

  // isHoriz(): boolean {
  //   return (this.track == 'horizontal')
  // }

  // nav(element: any) {
  //   const o: SmoothScrollToOptions = (this.isHoriz()) ? { left: 0 } : { top: 0 }
  //   this.scrollbarRef.scrollToElement(element, o)
  // }

  mouseWheelUpFunc(event: any) {
    // this.snapscroll(false)
  }

  mouseWheelDownFunc(event: any) {
    // this.snapscroll(true)
  }

  // snapscroll(forwards: boolean) {
  //   const sections = [this.sectionSlider, this.sectionWhy, this.sectionHow, this.sectionSuccess, this.sectionContact]

  //   const positions = sections.map(s => !this.isHoriz() ? s.nativeElement.offsetLeft : s.nativeElement.offsetTop)

  //   if (forwards) {
  //     for (const i in sections) {
  //       if (this.scrollPos + 10 < positions[i]) {
  //         this.nav(sections[i])
  //         return
  //       }
  //     }
  //   } else { // go back
  //     positions.reverse()
  //     sections.reverse()
  //     for (const i in sections) {
  //       if (this.scrollPos - 10 > positions[i]) {
  //         this.nav(sections[i])
  //         return
  //       }
  //     }
  //   }
  // }
}
