import { trigger, transition, style, animate, keyframes, query, stagger } from '@angular/animations'

export const inOutAnimation =
  trigger('inOutAnimation',
    [
      transition(
        ':enter',
        [
          query(
            '.b3, .b2, .b1, .menu', 
            [
              style({ transform: 'translateY(-100%)' }),
              stagger(
                160, 
                animate(
                  '400ms cubic-bezier(.11,.61,.57,.99)',
                  style({ transform: 'translateY(0)' })
                )
              )  
            ]
          )
          // animate('600ms ease-in-out',
          // keyframes([
          //     style({ transform: 'translateY(-100%)', offset: 0.3 }),
          //     style({ transform: 'translateY(0)', offset: 1 })
          //   ])
          // )
        ]
      ),
      transition(
        ':leave',
        [
          query(
            '.menu, .b1, .b2, .b3', 
            [
              style({ transform: 'translateY(0)' }),
              stagger(
                -60, 
                animate(
                  '200ms ease-out',
                  style({ transform: 'translateY(-100%)' })
                )
              ) 
            ]
          )
        ]
      )
    ]
  )
