import { Component, HostListener, OnInit } from '@angular/core'
import { timer } from 'rxjs'
import { PreloaderService } from '../../preloader/preloader.service'
import { inOutAnimation } from './animations'

@Component({
  selector: 'home-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    inOutAnimation
  ]
})
export class SliderComponent implements OnInit {

  constructor(private preloader: PreloaderService) { }

  slide = 0
  slideBkgIndex = [0, 1, 2, 3, 0, 1, 2, 3]

  imageIndex = 0
  images = [
    '/assets/home/mountains1.jpg',
    '/assets/home/eagle.jpg',
    '/assets/home/water.jpg',
    '/assets/home/trees_40.jpg',
    '/assets/home/mountains2.jpg',
  ]

  slideTimer = timer(0, 7500)
  paused = false

  ngOnInit(): void {
    this.preloader.spinner = true
    this.slideTimer.subscribe(x => this.next())
  }

  next() {
    if (this.paused || document.hidden) { return }

    this.slide += 1
    if (this.slide + 1 > this.slideBkgIndex.length) {
      this.slide = 0
    }
    this.imageIndex = this.slideBkgIndex[this.slide]
  }

  hidePreloader(event:any) {
    this.preloader.spinner = false
  }

  afterTransition() {
    // console.log('slider.component: transition finished')
  }

  appearing() {
    // console.log("slider PLAYING")
    this.paused = false 
  }

  disappearing() {
    // console.log("slider PAUSED")
    this.paused = true
  }

  @HostListener('document:visibilitychange', ['$event'])
  visibilitychange() {
    if (document.hidden){
      this.disappearing()
    } else {
      this.appearing()
    }
  }

}

