import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'

import { Observable, of } from 'rxjs'
import { catchError, mergeMap } from 'rxjs/operators'

import { WPPage } from '../../interfaces/wp-page'
import { setAuthHeaders } from '../helpers/set-auth-headers'
import { environment } from 'ui/environments/environment'


@Injectable({
  providedIn: 'root'
})
export class KaapisoftCMSService {

  constructor(
    private http: HttpClient
  ) { }

  

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getToken(data: { username: string, password: string }): Observable<{
    token?: string
    user_display_name?: string
    user_email?: string
    user_nicename?: string
  }> {
    const url = environment.wp + '/wp-json/jwt-auth/v1/token'
    return this.http.post<{
      token?: string
      user_display_name?: string
      user_email?: string
      user_nicename?: string
    }>(url, data)
      .pipe(catchError((error) => {
        console.log({ error })

        return of({
          token: undefined,
          user_display_name: undefined,
          user_email: undefined,
          user_nicename: undefined
        })
      }))
  }


  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getPage(pageId: number, token = ''): Observable<WPPage> {

    const headers = setAuthHeaders(token)
    const url = environment.wp + '/wp-json/wp/v2/pages/' + pageId
    return this.http.get<WPPage>(url, { headers })
      .pipe(catchError((error) => {
        console.error({ error })

        throw error
      }))
  }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getPages(token = ''): Observable<WPPage[]> {

    const headers = setAuthHeaders(token)
    const url = environment.wp + '/wp-json/wp/v2/pages'
    return this.http.get<WPPage[]>(url, { headers })
      .pipe(catchError((error) => {
        console.error({ error })

        throw error
      }))
  }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getPosts(token = ''): Observable<WPPage[]> {

    const headers = setAuthHeaders(token)
    // const url = environment.wp + '/wp-json/wp/v2/products'
    const url = environment.wp + '/wp-json/wp/v2/posts'
    return this.http.get<WPPage[]>(url, { headers })
      .pipe(catchError((error) => {
        console.error({ error })

        throw error
      }))
  }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getPost(postId: string, token = ''): Observable<WPPage> {

    const headers = setAuthHeaders(token)
    // const url = environment.wp + '/wp-json/wp/v2/products/' + postId
    const url = environment.wp + '/wp-json/wp/v2/posts/' + postId
    return this.http.get<WPPage>(url, { headers })
      .pipe(catchError((error) => {
        console.error({ error })
        throw error
      }))
  }

  getPostBySlug(slug: string, token = ''): Observable<WPPage> {

    const headers = setAuthHeaders(token)
    const url = environment.wp + '/wp-json/wp/v2/posts?slug=' + slug
    const request = this.http.get<WPPage>(url, { headers })
    
    return request.pipe(
      // mergeMap((response) => {
      //     return response
      //   }),
      catchError((error) => {
        console.error({ error })
        throw error
      })
      )//end pipe
  }

  getPageBySlug(slug: string, token = ''): Observable<WPPage> {

    const headers = setAuthHeaders(token)
    const url = environment.wp + '/wp-json/wp/v2/pages?slug=' + slug
    return this.http.get<WPPage>(url, { headers })
      .pipe(catchError((error) => {
        console.error({ error })
        throw error
      }))
  }


  /**
   * Handle HTTP error
   */
  private handleError(error: any): Observable<string[]> {
    // In a real world app, we might use a remote logging infrastructure
    // We'd also dig deeper into the error to get a better message
    const errMsg = (error.message) ? error.message :
      error.status ? `${error.status} - ${error.statusText}` : 'Server error'
    console.error(errMsg) // log to console instead

    // TODO: retunr string not string[]
    return of([errMsg])
  }
}
