import { Location, ViewportScroller } from '@angular/common'
import { AfterViewInit, Component, ViewChild } from '@angular/core'
import { Router, Scroll } from '@angular/router'
import { NgScrollbar } from 'ngx-scrollbar'
import { interval, Subscription } from 'rxjs'
import { debounce, delay, filter, tap } from 'rxjs/operators'
import { inout } from './animations'

// import { adminRoutes, defaultRoutes } from './app-routes'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [ inout ]
})
export class AppComponent implements AfterViewInit {

  @ViewChild(NgScrollbar) scrollbarRef!: NgScrollbar
  track: 'horizontal' | 'vertical' | 'all' = 'vertical'
  private scrollPos = 0
  scrollSubcriber?: Subscription

  title = 'kaapi'
  routeSubscriber?: Subscription
  fragment = ''

  showBackToTop = false

  constructor(private router: Router,
              private viewportScroller: ViewportScroller,
              private location: Location) {}

  ngAfterViewInit() {
    this.scrollSubcriber = this.scrollbarRef
          .scrolled?.pipe(
            debounce(() => interval(500))
          )
          .subscribe(e => {
            this.scrollPos = e.target.scrollTop 
            this.showBackToTop = (this.scrollPos > window.innerHeight) 
            // this.location.replaceState(this.location.path())
            // this.scrollPos = (this.track === 'vertical') ? e.target.scrollLeft : e.target.scrollTop
          })

    this.location.subscribe(e => {
      console.warn(e)
    })

    this.router.events.pipe(
      // tap(e => console.warn(e)),
      filter((e): e is Scroll => e instanceof Scroll),
      delay(10)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        this.scrollbarRef.scrollTo({ top: e.position[1] })
        // this.viewportScroller.scrollToPosition(e.position);
      } else if (e.anchor) {
        // anchor navigation
        const el = document.getElementById(e.anchor)
        const pos = el?.offsetTop || 0
        this.scrollbarRef.scrollTo({ top: pos })
        // this.viewportScroller.scrollToAnchor(e.anchor);
      } else {
        // forward navigation
        this.scrollbarRef.scrollTo({ top: 0 })
        // this.viewportScroller.scrollToPosition([0, 0]);
      }
    })

  }

  backToTop() {
    this.scrollbarRef.scrollTo({ top: 0 })
  }

  nav(element: any) {
    // const o: SmoothScrollToOptions = (this.isHoriz()) ? { left: 0 } : { top: 0 }
    // this.scrollbarRef.scrollToElement(element, o)
  }

  // snapscroll(forwards: boolean) {
  //   const sections = [this.sectionSlider, this.sectionWhy, this.sectionHow, this.sectionSuccess, this.sectionContact]

  //   const positions = sections.map(s => !this.isHoriz() ? s.nativeElement.offsetLeft : s.nativeElement.offsetTop)

  //   if (forwards) {
  //     for (const i in sections) {
  //       if (this.scrollPos + 10 < positions[i]) {
  //         this.nav(sections[i])
  //         return
  //       }
  //     }
  //   } else { // go back
  //     positions.reverse()
  //     sections.reverse()
  //     for (const i in sections) {
  //       if (this.scrollPos - 10 > positions[i]) {
  //         this.nav(sections[i])
  //         return
  //       }
  //     }
  //   }
  // }

}
