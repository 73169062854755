export const page = {
    head: {
        title: "Systems Integration – ERP&nbsp;Software",
        icon: "/assets/pages/systems-integration-cube.svg",
        p: [ 
            `Yes, we love that! It’s called Systems Integration and 
            we have successfully completed many software development 
            projects in this area.`,

            `Systems Integration simply means adjusting something so
             that two or more software or IT systems can ‘work together’
            instead of being separate and disconnected. `
        ]
    },
    caseStudy: {
        logo: '/assets/home/logos/bmit_logo.svg',
        text: 'See the BMIT case study',
        url: '/assets/case-study/CaseStudy_BMIT.pdf'
    },
    sectionA: {
        p1: [
            `For example, you might have a system that captures 
            customer and sales data from your points of sale, but 
            it is not working together with the customer support 
            team who are using yet another system to manage customer 
            information. What if both are connected and work fluidly 
            together?`
        ],
        p2: [
            `The answer is simple: Time-saving, more efficiency, cost-effective and 
            better workflows across your teams.`
        ],
        p3: [            
            `Integrating two or more systems together is also connected
             to the idea of Enterprise Resource Management Software (ERP)
            that is becoming increasingly on demand by today’s businesses.
             ERP is a way of managing the company’s internal resources 
             from one unified platform interface – for example your 
             payroll, HR, Sales & Marketing, etc.`,
            
            `The examples can go on and on. Ultimately any system 
            can be integrated with another within reason, so you can 
            imagine the potential.`
        ]
    },
    meta: {
        title: `Systems Integration Software Solutions | ERP Software | ERP for Small Business`,
        description: `Systems Integration and Enterprise Resource Planning ERP Software - Yes we love that! It’s called Systems Integration and we have successfully completed many software development projects in this area. Systems Integration simply means adjusting something so that two or more software or IT systems can ‘work together' instead of being separate and disconnected.`,
        keywords: `
            systems integration,
            sytems integration malta,
            erp,
            erp software,
            enterprise resource planning,
            enterprise resource planning software,
            enterprise resource planning erp,
            erp software malta,
            erp for small business   
        `
    }

}
