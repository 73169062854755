export const page = {
    head: {
        icon: '/assets/pages/it-oustaffing-puzzle.svg',
        title: "IT Outsourcing",
        h3: `In an increasingly fast-moving and agile business 
             environment, outsourcing your IT while focusing on
             the core operations of your business is no longer
             an option but a necessity. `
    },
    sectionA: {
        icon: '/assets/pages/it-oustaffing-A-puzzle.svg',
        p: [
            `KaapiSoft can help you ramp up your IT teams or 
             take care of entire IT projects from start to finish.
             We have provided successful IT outsourcing services 
             to clients who needed support in terms of application 
             development, <a href="/smarter-applications-and-workflow-automation/">automation of their workflows</a> or 
             <a href="/systems-integration-erp-software/">integrating some of their systems</a> for increased 
             efficiency and productivity.`
        ]
    },
    sectionB: {
        title: `Here are some of the benefits of IT outsourcing 
                KaapiSoft can bring to your business:`,
        items: [
            { 
                icon: '/assets/pages/it-outsourcing-icon-01.svg',
                title: `Agility and Cost-effectiveness`,
                p: `We bring in our experience straight to the table and land on your projects with our feet running. We save you critical time and resources in building up teams, planning and managing your IT needs. `
            },
            { 
                icon: '/assets/pages/it-outsourcing-icon-03.svg',
                title: `Experience in consulting and engineering solutions across a wide spectrum of industries`,
                p: `We have consulted and managed IT projects for businesses in different sectors and niches. Our experience lies not only with <a href="/bespoke-software-development">software development</a> and product solutions but also in understanding the business and market landscape you operate in.`
            },
            { 
                icon: '/assets/pages/it-outsourcing-icon-02.svg',
                title: `A skilled workforce to add to your winning team`,
                p: `Our team of experienced developers and IT experts will provide you with the necessary skills to carry out your projects thus avoiding the hassle of recruiting, vetting and managing people.`
            },
            { 
                icon: '/assets/pages/it-outsourcing-icon-04.svg',
                title: `Project management experience across a number of IT projects`,
                p: `As part of our IT outsourcing services, we offer project management for any type of project whether short term or long, complex and multi-stakeholder ones. Professional project management can make a huge bottom line difference in terms of both the cost and success of the project`
            }
        ]
    },
    sectionC: {
        text: `Talk to us so we can give you more help and information straight away or setup an appointment for a free consultation.`,
        button: `SETUP AN APPOINTMENT`
    },
    meta: {
        title: `IT Outsourcing in Malta`,
        description: `Outsource your IT services & IT Management - In an increasingly fast-moving and agile business environment, outsourcing your IT while focusing on the core operations of your business is no longer an option but a necessity. KaapiSoft can help you ramp up your IT teams or take care of entire IT projects from start to finish. We have provided successful IT outsourcing services… Continue reading IT Outsourcing`,
        keywords: `
        it outsourcing,it outsourcing services,it outsourcing companies,outsourced it support,outsourced it support services,outsourcing it operations,it outsourcing services company,outsourced it solutions, outsourced it service providers, it outsourcing provider, outsourced it management, it outsourcing solutions,it support,outsourcing companies,it projects outsourcing,it development outsourcing,it outsourcing business,outsource your it,small business it outsourcing,it services and outsourcing,outsourcing it development,outsourced it managed services,business it outsourcing,it consulting outsourcing,it outsourcing process,it resource outsourcing,it business process outsourcing,it services that can be outsourced,biggest it outsourcing companies,it outsourcing service providers`
    }

}
