import { trigger, transition, style, animate } from '@angular/animations'

export const inOutAnimation =
  trigger('inOutAnimation',
    [
    //   transition(
    //     ':enter',
    //     [
    //       style({ transform: 'translateY(30%)', opacity: 0 }),
    //       animate('5s ease-out',
    //         style({ transform: 'translateY(0)', opacity: 1 }))
    //     ]
    //   ),
    //   transition(
    //     ':leave',
    //     [
    //       style({ transform: 'translateY(0)', opacity: 1 }),
    //       animate('500ms ease-in',
    //         style({ transform: 'translateY(-50%)', opacity: 0 }))
    //     ]
    //   )
    ]
  )
