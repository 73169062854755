import { Routes } from '@angular/router'

import { HomeComponent } from './components/home/home.component'
import { DynamicPagesComponent } from './components/dynamic-pages/dynamic-pages.component'
import { Page404Component } from './components/page404/page404.component'
import { BespokeSoftwareDevelopmentComponent } from './components/pages/bespoke-software-development/bespoke-software-development.component'
import { SystemsIntegrationComponent } from './components/pages/systems-integration/systems-integration.component'
import { SmarterApplicationsComponent } from './components/pages/smarter-applications/smarter-applications.component'
import { ItOutstaffingServicesComponent } from './components/pages/it-outstaffing-services/it-outstaffing-services.component'
import { ItConsultancyComponent } from './components/pages/it-consultancy/it-consultancy.component'
import { ItOutsourcingComponent } from './components/pages/it-outsourcing/it-outsourcing.component'
import { AboutUsComponent } from './components/pages/about-us/about-us.component'

export const defaultRoutes: Routes = [
  {
    path: '404',
    component: Page404Component
  },
  // Site routes go here
  {
    path: '', component: HomeComponent,
    pathMatch: 'full'
  },

  { 
    path: 'bespoke-software-development',
    component: BespokeSoftwareDevelopmentComponent,
    pathMatch: 'full'
  },
  {
    path: 'systems-integration-erp-software',
    component: SystemsIntegrationComponent,
    pathMatch: 'full'    
  },
  {
    path: 'smarter-applications-and-workflow-automation',
    component: SmarterApplicationsComponent,
    pathMatch: 'full'
  },
  {
    path: 'it-outstaffing-services',
    component: ItOutstaffingServicesComponent,
    pathMatch: 'full'
  },
  {
    path: 'it-consultancy',
    component: ItConsultancyComponent,
    pathMatch: 'full'
  },
  {
    path: 'it-outsourcing',
    component: ItOutsourcingComponent,
    pathMatch: 'full'
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    pathMatch: 'full'
  },
  
  // dynamic pages : try loading from wp backend
  { 
    path: '**', 
    redirectTo: '/'
  }
]
