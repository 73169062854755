import { ElementRef, Injectable, NgZone, OnDestroy } from '@angular/core'

import { BehaviorSubject, Subject } from 'rxjs'

import * as THREE from 'three'
import { TimelineMax, Power2 } from 'gsap'

@Injectable({
  providedIn: 'root'
})
export class TransitionService implements OnDestroy {
  scene: THREE.Scene
  renderer!: THREE.WebGLRenderer
  camera: THREE.PerspectiveCamera
  textures: THREE.Texture[]
  plane!: THREE.Mesh
  material!: THREE.ShaderMaterial
  geometry: THREE.PlaneGeometry = new THREE.PlaneGeometry(1, 1, 2, 2)
  settings!: any

  public canvas!: HTMLCanvasElement
  public images: string[] = []
  vertex: string
  public fragment = ``
  public uniforms: any
  public debug = false
  public easing = 'easeInOut'
  public duration: any = 1

  private frameId?: number = undefined

  time: number
  current: number
  paused: boolean
  gui: any
  imageAspect?: number
  isRunning: any

  public transitionFinished$ = new Subject()
  public textureLoaded$ = new Subject()
  public texturesLoaded$ = new BehaviorSubject(false)

  constructor(private ngZone: NgZone) {
    this.scene = new THREE.Scene()
    this.vertex = `varying vec2 vUv;void main() {vUv = uv;gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );}`

    this.camera = new THREE.PerspectiveCamera(
      70,
      window.innerWidth / window.innerHeight,
      0.001,
      1000
    )

    this.camera.position.set(0, 0, 2)
    this.time = 0
    this.current = 0
    this.textures = []

    this.paused = true
  }

  public ngOnDestroy(): void {
    if (this.frameId !== undefined) {
      cancelAnimationFrame(this.frameId)
    }
  }

  public setScene(
    container: ElementRef<HTMLCanvasElement>,
    images: string[],
    fragment: string,
    uniforms: any
  ): void {

    this.canvas = container.nativeElement

    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      // alpha: true,    // transparent background
      // antialias: true // smooth edges
    })
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setClearColor(0xeeeeee, 0)

    this.images = images
    this.fragment = fragment
    this.uniforms = uniforms

    this.loadTextures().then(() => {
      // console.log(this.textures)
      this.setSettings()
      this.addObjects()
      this.resize()
      this.animate()
      this.texturesLoaded$.next(true)
    })
  }


  loadTextures(): Promise<THREE.Texture[]> {
    const promises: Promise<THREE.Texture>[] = []
    const that = this
    this.images.forEach((url, i) => {
      const promise = new Promise<THREE.Texture>(resolve => {
        that.textures[i] = new THREE.TextureLoader().load(url, resolve)
        that.textureLoaded$.next(i)
      })
      promises.push(promise)
    })

    return Promise.all(promises)

    // Promise.all(promises).then(() => {
    //   cb();
    // });
  }

  setSettings(): void {
    this.settings = { progress: 0.1 }

    Object.keys(this.uniforms).forEach((item) => {
      this.settings[item] = this.uniforms[item].value
    })
  }

  public animate(): void {
    // We have to run this outside angular zones,
    // because it could trigger heavy changeDetection cycles.
    this.ngZone.runOutsideAngular(() => {
      if (document.readyState !== 'loading') {
        this.resume()
      } else {
        window.addEventListener('DOMContentLoaded', () => {
          this.resume()
        })
      }

      window.addEventListener('resize', () => {
        this.resize()
      })
    })
  }

  public resize(): void {
    const width = window.innerWidth
    const height = window.innerHeight
    this.renderer.setSize(width, height)

    this.camera.aspect = width / height

    if (this.textures[0].image === undefined) { return }

    // image cover
    this.imageAspect = this.textures[0].image.height / this.textures[0].image.width
    let a1; let a2
    if (height / width > this.imageAspect) {
      a1 = (width / height) * this.imageAspect
      a2 = 1
    } else {
      a1 = 1
      a2 = (height / width) / this.imageAspect
    }

    this.material.uniforms.resolution.value.x = width
    this.material.uniforms.resolution.value.y = height
    this.material.uniforms.resolution.value.z = a1
    this.material.uniforms.resolution.value.w = a2

    const dist = this.camera.position.z
    const iheight = 1
    this.camera.fov = 2 * (180 / Math.PI) * Math.atan(iheight / (2 * dist))

    this.plane.scale.x = this.camera.aspect
    this.plane.scale.y = 1

    this.camera.updateProjectionMatrix()
  }


  addObjects(): void {
    const that = this
    this.material = new THREE.ShaderMaterial({
      extensions: {
        derivatives: true
      },
      side: THREE.DoubleSide,
      uniforms: {
        time: { value: 0.0 },
        progress: { value: 0.0 },
        border: { value: 0.0 },
        intensity: { value: 0.0 },
        scaleX: { value: 40.0 },
        scaleY: { value: 40.0 },
        transition: { value: 40.0 },
        swipe: { value: 0 },
        width: { value: 0 },
        radius: { value: 0 },
        texture1: { value: this.textures[0] },
        texture2: { value: this.textures[1] },
        displacement: { value: new THREE.TextureLoader().load('assets/disp1.jpg') },
        resolution: { value: new THREE.Vector4() },
      },
      // wireframe: true,
      vertexShader: this.vertex,
      fragmentShader: this.fragment
    })

    this.geometry = new THREE.PlaneGeometry(1, 1, 2, 2)

    this.plane = new THREE.Mesh(this.geometry, this.material)
    this.scene.add(this.plane)
  }

  pause(): void {
    // console.log("PAUSE");
    this.paused = true
  }

  resume(): void {
    // console.log("resume");
    this.paused = false
    this.render(-1)
  }

  goTo(index: number): void {
    if (!this.material) { return }
    if (this.isRunning) { return }
    this.resume()
    const len = this.textures.length
    const nextTexture = this.textures[index]
    this.material.uniforms.texture2.value = nextTexture

    const tl = new TimelineMax()
    tl.to(this.material.uniforms.progress,
      this.duration,
      {
        value: 1,
        ease: Power2.easeInOut,
        // ease: Power2[this.easing],
        onComplete: () => {
          // console.log('FINISH');
          this.current = (this.current + 1) % len
          this.material.uniforms.texture1.value = nextTexture
          this.material.uniforms.progress.value = 0
          this.pause()
          this.isRunning = false
          this.transitionFinished$.next()
        }
      })
  }

  next(): void {
    if (this.isRunning) { return }
    this.isRunning = true
    const len = this.textures.length
    const nextTexture = this.textures[(this.current + 1) % len]
    this.material.uniforms.texture2.value = nextTexture

    const tl = new TimelineMax()
    tl.to(this.material.uniforms.progress,
      this.duration,
      {
        value: 1,
        ease: Power2.easeInOut,
        // ease: Power2[this.easing],
        onComplete: () => {
          console.log('FINISH')
          this.current = (this.current + 1) % len
          this.material.uniforms.texture1.value = nextTexture
          this.material.uniforms.progress.value = 0
          this.isRunning = false
        }
      })
  }

  render(timestamp: DOMHighResTimeStamp): void {
    if (this.paused) { return }
    if (!this.material) { return }
    // this.time += 0.05;
    // this.material.uniforms.time.value = this.time;
    if (this.material.uniforms.time.value === timestamp) { return }
    this.material.uniforms.time.value = timestamp
    // this.material.uniforms.progress.value = this.settings.progress;

    Object.keys(this.uniforms).forEach((item) => {
      this.material.uniforms[item].value = this.settings[item]
    })

    // this.camera.position.z = 3;
    // this.plane.rotation.y = 0.4*Math.sin(this.time)
    // this.plane.rotation.x = 0.5*Math.sin(0.4*this.time)

    this.frameId = requestAnimationFrame(this.render.bind(this))
    this.renderer.render(this.scene, this.camera)
  }
}
