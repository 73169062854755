<div id="contact">
  <div class="bkg-image"></div>
  <section class="content dark-theme">
    <div class="pad">
      <div class="contact-info">
        <h1>Contact us</h1>
        <p class="head">Schedule a meeting with us.</p>
        <div class="row"><p class="space"></p></div>
        <div class="row" (click)="newTab('tel:+356 7904 6188')">
          <img src="/assets/contact-phone.svg" />
          <p><strong>+356 7904 6188</strong></p>
        </div>
        <div class="row" (click)="newTab('mailto:hello@kaapisoft.com')">
          <img src="/assets/contact-email.svg" />
          <p>hello@kaapisoft.com</p>
        </div>
        <div class="row" (click)="newTab('https://www.facebook.com/kaapisoft/')">
          <img src="/assets/contact-facebook.svg" />
          <p>Facebook </p>
        </div>
        <div class="row" (click)="newTab('https://www.linkedin.com/company/kaapisoft/')">
          <img src="/assets/contact-linkedin.svg" />
          <p>LinkedIn</p>
        </div>
        <div class="row"><div class="space">&nbsp;</div></div>
        <div class="row"><img src="/assets/contact-globe.svg" />
          <p>
            <strong>KaapiSoft Ltd</strong><br/>
            NXR3651 Naxxar<br/>
            Malta
          </p></div>
        <p></p>
        <!-- <p>MT 24724311</p> -->
      </div>

      <!-- <div class="contact-form">
        <app-dynamic-form
          *ngIf="form && form.controls"
          [questions]="form.controls"
          submitButton="SCHEDULE MEETING"
          (formSubmitted)="submitForm($event)"
        ></app-dynamic-form>
      </div> -->

      <!-- <form>
        <mat-form-field class="example-full-width">
          <mat-label>Email</mat-label>
          <input type="email" matInput placeholder="Ex. pat@example.com" />
          [formControl]="emailFormControl"
          [errorStateMatcher]="matcher"
          <mat-hint>Errors appear instantly!</mat-hint>
          <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                      Please enter a valid email address
                    </mat-error>
          <mat-error *ngIf="emailFormControl.hasError('required')">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </form> -->
    </div>
    <div class="space">
    </div>
    <div class="bleft">
      <ng-lottie-autoplay
        src="/assets/contact-logo.json"
        class="box"></ng-lottie-autoplay>
    </div>
  </section>
</div>
