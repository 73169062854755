import { Component, Input, OnInit } from '@angular/core';
import { CaseStudy } from 'ui/app/core/interfaces/case-study';

@Component({
  selector: 'case-study-box',
  templateUrl: './case-study-box.component.html',
  styleUrls: ['./case-study-box.component.scss']
})
export class CaseStudyBoxComponent implements OnInit {

  @Input() data?: CaseStudy
  @Input() boxStyle: string = ""

  constructor() { }

  ngOnInit(): void {
  }

  open() {
    window.open(this.data?.url, "_blank")
  }
}
