import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { page } from '../../../_content/page-it-consultancy';

@Component({
  selector: 'app-it-consultancy',
  templateUrl: './it-consultancy.component.html',
  styleUrls: ['./it-consultancy.component.scss']
})
export class ItConsultancyComponent implements OnInit {
  
  page = page

  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) { }

  ngOnInit(): void {
    this.titleService.setTitle(page.meta.title)
    this.metaService.updateTag(
      { name: 'keywords', content: this.page.meta.keywords })
    this.metaService.updateTag(
      { name: 'description', content: this.page.meta.description })
    this.metaService.updateTag(
      { name: 'robots', content: 'index, follow' })
  }


}
