import { ElementRef, HostBinding, Component, Input, OnChanges } from '@angular/core'
import { animate, style, transition, trigger } from '@angular/animations'

@Component({
  selector: 'smooth-height',
  template: `<ng-content></ng-content>`,
  styles: [`
    :host {
      display: block;
      overflow: hidden;
    }
  `],
  animations: [
    trigger('grow', [
      transition('void <=> *', []),
      transition('* <=> *', [
        style({ height: '{{startHeight}}px', opacity: 0 }),
        animate('.5s ease'),
      ], { params: { startHeight: 0 } })
    ])
  ]
})
export class SmoothHeightComponent implements OnChanges {
  @Input()
  trigger: any

  startHeight = 0

  constructor(private element: ElementRef) { }

  // @HostBinding('@grow') 
  get grow() {
    return { value: this.trigger, params: { startHeight: this.startHeight } }
  }

  setStartHeight(): void {
    this.startHeight = this.element.nativeElement.clientHeight
  }

  ngOnChanges(): void {
    this.setStartHeight()
  }
}
