export const page = {
    meta: {
        title: `About Us`,
        description: ``,
        keywords: ``
    },
    head: {
        icon: '/assets/logo.svg',
        title: "Our Mission",
        sub: "To develop solutions with software that lasts."
    },
    sectionA : {
        title: "Who We Are",
        p: [
            `KaapiSoft is a software development and consultancy services provider specialising in the custom development of business applications and software solutions. Composed of a lean and agile team, our goal is to provide software systems of high value and standard, using modern technologies and software design patterns.`,
            `KaapiSoft started off as an outsourcing software development provider, focusing in providing specialized expertise and talent in projects which require re alignment in quality and efficiency. Now it is expanding in providing services in critical projects supplied to industries ranging from technology, architecture and house design, social media, fintech, GDPR solutions and more.`,
            `As a Start Up, our key points in maintaining an organic growth are based on the basic principle of providing value to businesses who choose us to help them out in their software infrastructure in a way that our services are seen as an investment and with a sense of satisfaction and value for money.`
        ]
    },
    card: {
        title: "Our Values",
        items: [
            {
                icon: `/assets/pages/about/about-us-icon-collaborate.svg`,
                text: `We work collaboratively with our clients towards reaching the desired result.`
            },
            {
                icon: `/assets/pages/about/about-us-icon-quality.svg`,
                text: `We are committed to deliver the best possible solutions for our clients through work built on quality and integrity.`
            },
            {
                icon: `/assets/pages/about/about-us-icon-care.svg`,
                text: `We care for each other, our clients, and their businesses and strive to drive their success.`
            },
            {
                icon: `/assets/pages/about/about-us-icon-value.svg`,
                text: `Above all, we seek to provide value in a way that our services are seen as an investment.`
            }
        ]
    },
    moose: `/assets/pages/about/about-us-moose.png`,
    sectionC: {
        title: {
            first: "Let us help",
            second: "you succeed"
        },
        p: `Our team is ready to meet your software needs!`,
        buttonText: `CONTACT US NOW`
    }
}