<div id="head">
    <div class="grid">
        <div class="blue">
            <div class="icon" [inlineSVG]="page.head.icon"></div>
        </div>
        <section class="text">
            <h1 class="line-left"
                [innerHtml]="page.head.title"></h1>
            <h3 class="text"
                [innerHtml]="page.head.h3"></h3>
             <p class="text justify"
               [innerHtml]="page.head.p"></p>
        </section>
    </div>
</div>
<div class="ABC-container">
    <section id="A">
        <div class="icon">
            <div [inlineSVG]="page.sectionA.icon"></div>
        </div>
        <div class="text">
            <p *ngFor="let pg of page.sectionA.p" 
                class="justify"
                [innerHtml]="pg"></p>
        </div>
    </section>
    <section id="B">
        <h2 class="line-left" 
            [innerHtml]="page.sectionB.title"></h2>
        <div class="text">
            <p *ngFor="let pg of page.sectionB.p" 
            class="justify"
            [innerHtml]="pg"></p>
        </div>
    </section>
    <section id="C">
        <h3 [innerHtml]="page.sectionC.text"></h3>
        <div class="kbutton" routerLink="/" fragment="contact">{{ page.sectionC.button }}</div>
    </section>
</div>