import { NgModule } from '@angular/core'
import { RouterModule } from '@angular/router'
import { defaultRoutes } from './app-routes'
import { SharedModule } from './shared/shared.module'

@NgModule({
  imports: [
    RouterModule.forRoot(defaultRoutes, 
      { relativeLinkResolution: 'legacy',
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
    SharedModule],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
