<div id="header">
    <div id="backdrop">
        <div id="overlay"></div>
        <div id="content">
            <div id="icon" [inlineSVG]="page.head.icon"></div>
            <h1 id="title" [innerHtml]="page.head.title"></h1>
            <h2 id="sub" [innerHtml]="page.head.sub"></h2>
        </div>
    </div>
</div>

<div class="page-container">

    <div class="A-container">
        <section id="A">
            
            <div class="text">
                <h2 [innerHtml]="page.sectionA.title"></h2>
                <p 
                    *ngFor="let pg of page.sectionA.p"
                    [innerHtml]="pg"></p>

                <div id="card">
                    <div id="card-title">
                        <h2 [innerHtml]="page.card.title"></h2>
                    </div>
                    <div id="card-body">
                        <div 
                            class="card-items"
                            *ngFor="let item of page.card.items">
                            <div
                                class="item-icon" 
                                [inlineSVG]="item.icon"
                                [setSVGAttributes]="{ width: '100%', height: '100%' }"></div>
                            <div 
                                class="item-text"
                                [innerHtml]="item.text"></div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="animated-squares">
                <ng-lottie-autoplay
                    src="/assets/success-squares.json"
                    class="squares"
                ></ng-lottie-autoplay>
            </div>

        </section>
    </div>

    <div id="B">
            <div id="moose">
                <img src="{{ page.moose }}" alt="moose">
            </div>
    </div>
</div>

<div id="C">
    <div id="text">
        <h1 [innerHtml]="page.sectionC.title.first"></h1>
        <h1 [innerHtml]="page.sectionC.title.second"></h1>
        <h3 [innerHtml]="page.sectionC.p"></h3>
    </div>
    <div id="button">
        <button class="kbutton" routerLink="/" fragment="contact">{{ page.sectionC.buttonText }}</button>
    </div>
</div>