export const page = {
    head: {
        icon: '/assets/pages/it-oustaffing-puzzle.svg',
        title: "IT Outstaffing Services",
        h3: `We are ready to help you grow your team or beef 
             up your team temporarily in busy periods.`,
        p:  `If you already have a small IT team but lack 
             in-house developers or perhaps you need more 
             hands-on-deck for a particular project, you 
             might just want to ‘borrow’ some human resource 
             without hiring someone full-time.`,
        button: `Talk to us to setup an appointment`
    },
    sectionA: {
        icon: '/assets/pages/it-oustaffing-A-puzzle.svg',
        p: [`This is a new concept that more companies are 
               starting to adopt and we are there to help you 
               with that as well.  Particularly in IT and some 
               other industries, there are times when an extra 
               pair of hands or two are urgently needed to cover
               a certain project or part of a project under a 
               strict deadline. In this case it doesn’t make
                sense to hire someone full-time and it’s easier
                and more cost-effective to just get someone 
                on board specifically for a given set of tasks
                just like a freelance contractor, but better.`
        ]
    },
    sectionB: {
        title: `What is the difference from hiring a freelance contractor? `,
        p: [
            `The difference is subtle but important. A freelance contractor works alone usually remotely and unsupervised. The problem is that if you do not have an already working relationship with a particular freelancer, it is hard to know if he or she is a right match for the project especially if you are very tight on time under a deadline. You would need to search, interview, perhaps even test someone before contracting and you lose precious time along the way until the right match is found. `,
            `Here is where we can help. We can take the time and pressure off your hand and help you acquire the right match for your needs such as a developer with a particular set of skills and expertise in a particular technology stack. `,
            `More importantly, we will be responsible to manage and track the performance of the developer and in doing so we are putting in our own quality assurance under the KaapiSoft brand name. `
        ]
    },
    sectionC: {
        text: `Talk to us so we can give you more help and information straight away or setup an appointment for a free consultation.`,
        button: `SETUP AN APPOINTMENT`
    },
    meta: {
        title: `IT Outstaffing Services`,
        description: `IT Outstaffing Services in Malta - We are ready to help you grow your team or beef up your team temporarily in busy periods. If you already have a small IT team but lack in-house developers or perhaps you need more hands-on-deck for a particular project, you might just want to ‘borrow’ some human resource without hiring someone full-time. Talk to… Continue reading IT Outstaffing Services`,
        keywords: `
        it outstaffing,
        it staff outsourcing,
        it outstaffing company,
        it outstaffing services,
        it outsourcing,
        it outsourcing malta
        `
    }

}
