import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  spinner$: BehaviorSubject<any> = new BehaviorSubject(false)

  constructor() { }

  set spinner(newValue: boolean) {
    this.spinner$.next(newValue)
  }

  get showSpinner(): boolean {
    return this.spinner$.getValue()
  }
}
