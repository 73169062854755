import { Injectable } from '@angular/core'

import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

import { KaapisoftCMSService } from './kaapisoft-cms.service'
import { WPPageData } from '../../interfaces/wp-page-data'
import { WPPage } from '../../interfaces/wp-page'

@Injectable({
  providedIn: 'root'
})
export class KaapisoftCMSStore {

  constructor(
    private cmsService: KaapisoftCMSService
  ) { }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getToken(data: { username: string, password: string }): Observable<{
    token?: string
    user_display_name?: string
    user_email?: string
    user_nicename?: string
  }> {

    return this.cmsService.getToken(data)
  }


  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getPages(token = ''): Observable<WPPageData[]> {

    return this.cmsService.getPages(token)
      .pipe(
        map((wpPages) => wpPages.map((wpPage) => this.castWPPageToWPPageData(wpPage))
        ))
  }


  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getPage(pageId: number, token = ''): Observable<WPPageData> {

    return this.cmsService.getPage(pageId, token)
      .pipe(
        map((wpPage) => this.castWPPageToWPPageData(wpPage)
        ))
  }
  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getPosts(token = ''): Observable<WPPageData[]> {

    return this.cmsService.getPosts(token)
      .pipe(
        map((wpPages) => wpPages.map((wpPage) => this.castWPPageToWPPageData(wpPage)))
      )
  }

  /**
   * Returns an Observable for the HTTP GET request for the JSON resource.
   */
  getPost(postId: string, token = ''): Observable<WPPageData> {

    return this.cmsService.getPost(postId, token).pipe(
      map((wpPage) => this.castWPPageToWPPageData(wpPage))
    )
  }

  private castWPPageToWPPageData(wpPage: WPPage): WPPageData {

    const wpPageData = {
      id: wpPage.id,
      date: wpPage.date,
      modified: wpPage.modified,
      title: wpPage.title.rendered,
      content: wpPage.content.rendered,
      excerpt: wpPage.excerpt.rendered,
      slug: wpPage.slug,
      metaTitle: wpPage._aioseo_title,
      metaDescription: wpPage._aioseo_description,
      metaTags: wpPage._aioseo_keywords
    }

    console.log({ wpPageData })

    return wpPageData
  }
}
