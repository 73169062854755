export const page = {
    meta: {
        title: `KaapiSoft | Software Development Company in Malta`,
        description: `KaapiSoft is a software company specializing in cutting edge Software Solutions. Bridging the gap between technical expertise and your software needs.`,
        keywords: `
        software development
        Software development Malta 
        Software company Malta
        software solutions
        software development company
        software company
        software projects
        software solutions company
        development company
        software engineering
        rapid application development
        bespoke software
        lean development
        crm software
        project management software
        payroll software
        inventory management software
        pos software
        hr software
        digital product development
        workflow automation
        erp
        erp software
        `
    }
}