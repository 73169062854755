export const page = {
    head: {
        icon: '/assets/pages/smarter-apps-brick.svg',
        title: "Smarter Applications and Workflow Automation",
        p: [
            `Having an efficient business running requires two
             things at its core – building the right team of 
             skilled people and having in place the right 
             software solutions and automated workflows.`,

            `Many organizations fail to have in place the 
            right workflows and as the business grows, 
            they realize that their applications are not 
            adequate or smart enough to deliver efficiently.`
        ]
    },
    caseStudy: {
        logo: '/assets/home/logos/bmit_logo.svg',
        text: 'See the BMIT case study',
        url: '/assets/case-study/CaseStudy_BMIT.pdf'
    },
    sectionA: {
        title: `This is how we can help you grow:`,
        items: [
            { icon: '/assets/pages/smarter-apps-icon1.svg',
              text: `We understand that since you need to stay focused on the 
                    business operations and critical areas of your company,
                    there is little time to plan and strategise on the IT 
                    aspect of the business. Yet it is crucial to move forward and grow.`
            },
            { icon: '/assets/pages/smarter-apps-icon2.svg',
              text: `We have helped many companies develop software solutions 
                    and applications to run part of their operations and help 
                    some of their departments and teams.  `
            },
            { icon: '/assets/pages/smarter-apps-icon3.svg',
              text: `We can offer digital product development that custom-fit 
                     your particular needs and goals increasing productivity, 
                     efficiency and flexibility within your teams. `
            },
            { icon: '/assets/pages/smarter-apps-icon4.svg',
               text: `We can also interface two of your systems to work smoother
                      together avoiding extra management and administration time 
                      or tedious time-wasters such as double inputting of data 
                      or reporting.`
            }
        ]
    },
    meta: {
        title: `Smarter Applications and Workflow Automation`,
        description: `Digital Product Application Development and Workflow Automation for Enterprises and Small Businesses - Having an efficient business running requires two things at its core – building the right team of skilled people and having in place the right software solutions and automated workflows. Many organizations fail to have in place the right workflows and as the business grows, they realize that their applications are not adequate or smart… Continue reading Smarter Applications and Workflow Automation`,
        keywords: `
        software solutions,
        workflow automation,
        software application development malta,
        software engineering malta,
        application development,
        crm software,
        project management software,
        payroll software,
        inventory management software,
        pos software,
        hr software,
        warehouse management software,
        facility management software,
        rental property management software,
        workflow automation software,
        software workflow,
        business automation workflow,
        accounts payable workflow,
        business process workflow,
        workflow automation tools,
        document workflow automation,
        workflow processes,
        business workflow software,
        workflow automation examples,
        applied automation workflow,
        accounts payable workflow automation,
        workflow process software,
        business workflows,
        enterprise workflow software,
        workflow automation platform,
        enterprise workflow automation,
        sharepoint workflow automation,
        forms automation,
        business workflow automation
        `
    }

}
