<div *ngIf="form && question">
  <form [formGroup]="form">
    <mat-form-field class="example-full-width">
      <mat-label [attr.for]="question.key">{{ question.label }}</mat-label>

      <div [ngSwitch]="question.controlType">
        <input
          matInput
          [placeholder]="question.placeholder"
          *ngSwitchCase="'textbox'"
          [formControlName]="question.key"
          [id]="question.key"
          [type]="question.type"
        />

        <textarea
          matInput
          [placeholder]="question.placeholder"
          *ngSwitchCase="'textarea'"
          [formControlName]="question.key"
          [id]="question.key"
          [type]="question.type"
        ></textarea>

        <!-- <mat-select
          [formControlName]="question.key"
          appearance="fill"
          [id]="question.key"
          *ngSwitchCase="'dropdown'"
          placeholder="Job Type"
        >
          <mat-option *ngFor="let opt of question.options" [value]="opt.key">
            {{ opt.value }}
          </mat-option>
        </mat-select>

        <div *ngSwitchCase="'datepicker'" appearance="fill">
          <input
            matInput
            [placeholder]="question.placeholder"
            [formControlName]="question.key"
            [id]="question.key"
            [type]="question.type"
            [matDatepicker]="picker"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </div> -->
        <!-- <input
          matInput
          [placeholder]="question.placeholder"
          *ngSwitchCase="'datepicker'"
          [formControlName]="question.key"
          [id]="question.key"
          [type]="question.type"
          [matDatepicker]="picker"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker> -->
      </div>

      <mat-error *ngIf="!isValid">
        <strong>{{ question.label }}</strong> is required
      </mat-error>
    </mat-form-field>
  </form>
</div>
