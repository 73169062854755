import { HttpHeaders } from '@angular/common/http'

/**
 * Handle HTTP Http Auth Headers
 */

export const setAuthHeaders = (token: string): HttpHeaders => {
  const headers = new HttpHeaders({
    'Content-Type': 'application/json'
  })
  if (token) {
    headers.append('Authorization', `Bearer ${token}`)
  }
  return headers
}
