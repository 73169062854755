<div id="head">
    <section class="grid">
        <div id="icon" [inlineSVG]="page.head.icon"></div>
        <h1 id="title"
            [innerHtml]="page.head.title"></h1>
        <div id="text">
            <p *ngFor="let pg of page.head.p" 
                class="justify"
                [innerHtml]="pg"></p>
        </div>
    </section>
</div>

<div class="A-container">
    <div class="large-logo" [inlineSVG]="'/assets/logo.svg'"></div>
    <section id="A">
        <h2 class="line-left"
            [innerHtml]="page.sectionA.title"></h2>
        <div class="items">
            <div *ngFor="let i of page.sectionA.items" 
                class="card">
                <img class="icon" src="{{ i.icon }}" />
                <p class="justify" [innerHtml]="i.text"></p>
            </div>
        </div>
        <div class="case-study">
            <case-study-box 
                [data]="page.caseStudy"
                boxStyle="teal">
            </case-study-box>
        </div>
        <div class="kbutton" routerLink="/" fragment="contact">GET IN TOUCH</div>
    </section>
</div>