<div id="how">
    <div class="bkg-image"></div>
    <div class="bleft"></div>
    <section class="content">
        <div class="txt" 
        observeIntersection
        [intersectionSteps]='2'
        (onAppearing)="animateLine($event)"
        (didDisappear)="offscreen()">
            <div class="box">
                <ng-lottie
                width="100%"
                height="100%"
                containerClass="kbox"
                [options]="lineOptions"
                [styles]="lineStyles"
                (animationCreated)="lineCreated($event)"
                ></ng-lottie>
            </div>
            <h1 class="title" [ngClass]="{'show': showTitle }">How can we help?</h1>
            <div class="item" routerLink="/it-consultancy">
                <div class="icon">                        
                    <div inlineSVG="/assets/home/how-01.svg" 
                         [setSVGAttributes]="iconStyle"></div>
                </div>
                <div class="item-text">                        
                    <p>I need IT Consultancy</p>
                </div>
                <div class="arrow"></div>
            </div>
            <div class="item" routerLink="/bespoke-software-development">
                <div class="icon">                        
                    <div inlineSVG="/assets/home/how-02.svg"
                         [setSVGAttributes]="iconStyle"></div>
                </div>
                <p>I need a software application custom-made for my business project</p>
                <div class="arrow"></div>
            </div>
            <div class="item" routerLink="/smarter-applications-and-workflow-automation">
                <div class="icon">                        
                    <div inlineSVG="/assets/home/how-03.svg"
                         [setSVGAttributes]="iconStyle"></div>
                </div>
                <p>I need to build smarter applications and more efficient workflows</p>
                <div class="arrow"></div>
            </div>
            <div class="item" routerLink="/systems-integration-erp-software">
                <div class="icon">                        
                    <div inlineSVG="/assets/home/how-04.svg"
                         [setSVGAttributes]="iconStyle"></div>
                </div>
                <p>I need to integrate two of my IT systems together for better workflow</p>
                <div class="arrow"></div>
            </div>
            <div class="item" routerLink="/it-outsourcing/">
                <div class="icon">                        
                    <div inlineSVG="/assets/home/how-05.svg"
                        [setSVGAttributes]="iconStyle"></div>
                </div>
                <div class="item-text">                  
                    <p>I need to outsource IT management & development </p>
                </div>
                <div class="arrow"></div>
            </div>
        </div>
    </section>
    <div class="bright"></div>
</div>