export class QuestionBase<T> {
  value?: T | null
  key: string
  label: string
  required: boolean
  order: number
  controlType: string
  type: string
  placeholder: string
  options: { key: string, value: string }[]

  constructor(options: {
    value?: T | null;
    key?: string;
    label?: string;
    required?: boolean;
    order?: number;
    controlType?: string;
    type?: string;
    placeholder?: string
    options?: { key: string, value: string }[];
  } = {}) {
    this.value = options.value
    this.key = options.key || ''
    this.label = options.label || ''
    this.required = !!options.required
    this.order = options.order === undefined ? 1 : options.order
    this.controlType = options.controlType || ''
    this.type = options.type || ''
    this.placeholder = options.placeholder || ''
    this.options = options.options || []
  }
}
